import React from 'react';
import Dropdown from 'components/dropdown';

const MetricsDropdown = ({ domainName }) => {
  return (
    <Dropdown
      button={
        <button className="rounded-md border border-gray-300 px-2 py-1 text-gray-700 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50">
          {domainName}
        </button>
      }
      classNames="transition-all duration-300 ease-in-out p-2 left-0 top-9 min-w-[160px] shadow-xl bg-white rounded-md"
    >
      <a
        href={`https://app.ahrefs.com/v2-site-explorer/overview?target=${domainName}`}
        target="_blank"
        rel="noreferrer"
        className="block w-full cursor-pointer rounded-md border border-gray-300 bg-white p-1 text-center hover:bg-gray-100"
      >
        Open in Ahrefs
      </a>
      <a
        href={`https://majestic.com/reports/site-explorer?folder=&IndexDataSource=&q=${domainName}`}
        target="_blank"
        rel="noreferrer"
        className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-1 text-center hover:bg-gray-100"
      >
        Open in Majestic
      </a>
      <a
        href={`https://www.semrush.com/analytics/overview/?q=tnonline.uol.com.br&searchType=${domainName}`}
        target="_blank"
        rel="noreferrer"
        className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-1 text-center hover:bg-gray-100"
      >
        Open in Semrush
      </a>
      <a
        href={`https://${domainName}`}
        target="_blank"
        rel="noreferrer"
        className="mt-1 block w-full rounded-md border border-gray-300 bg-white p-1 text-center hover:bg-gray-100"
      >
        Open in Browser
      </a>
    </Dropdown>
  );
};

export default MetricsDropdown;
