/* eslint-disable */
import React, { useContext, useEffect, useState, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { HiX } from 'react-icons/hi';
import Links from './components/Links';
import { ProjectContext } from 'contexts/ProjectContext';
import SidebarCard from 'components/sidebar/components/SidebarCard';
import {
  renderThumb,
  renderTrack,
  renderView,
  renderViewMini,
} from 'components/scrollbar/Scrollbar';
import { Scrollbars } from 'react-custom-scrollbars-2';
import routes from 'routes';
import Card from 'components/card';
import { Link } from 'react-router-dom';
import { get, getUserId } from 'api';
import logo from 'assets/img/logo/logo.png';
import icon from 'assets/img/logo/Icon.png';

function SidebarHorizon(props) {
  const navigate = useNavigate();
  const { open, onClose, variant, mini, hovered, setHovered } = props;
  const sidebarRef = useRef(null);

  useEffect(() => {
    function handleClickOutside(event) {
      if (
        window.innerWidth <= 768 &&
        sidebarRef.current &&
        !sidebarRef.current.contains(event.target)
      ) {
        onClose();
      }
    }

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClose]);

  return (
    <div
      ref={sidebarRef}
      className={`sm:none ${
        mini === false
          ? 'w-[245px]'
          : mini === true && hovered === true
          ? 'w-[245px]'
          : 'w-[245px] xl:!w-[120px]'
      } duration-175 linear fixed !z-50 min-h-full transition-all md:!z-50 lg:!z-50 xl:!z-0 ${
        variant === 'auth' ? 'xl:hidden' : 'xl:block'
      } ${open ? '' : '-translate-x-[105%]'}`}
      onMouseEnter={() => setHovered(true)}
      onMouseLeave={() => setHovered(false)}
    >
      <Card extra={`w-full h-[100vh] m-0 p-2 !rounded-[20px]`}>
        <Scrollbars
          autoHide
          renderTrackVertical={renderTrack}
          renderThumbVertical={renderThumb}
          renderView={
            mini === false
              ? renderView
              : mini === true && hovered === true
              ? renderView
              : renderViewMini
          }
        >
          <div className="flex h-full flex-col justify-between">
            <div>
              <span
                className="absolute right-4 top-4 block cursor-pointer xl:hidden"
                onClick={onClose}
              >
                <HiX />
              </span>
              <div
                className={`ml-[12px] mr-[24px] mt-[44px] flex items-center `}
              >
                <div
                  className={`font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? 'block'
                      : mini === true && hovered === true
                      ? 'block'
                      : 'hidden'
                  }`}
                >
                  <div className="flex items-center">
                    <Link
                      to="/admin/dashboards/backlinks-search"
                      sx={{ display: 'flex', alignItems: 'center' }}
                    >
                      <img
                        src={logo}
                        alt="logo"
                        className="h-8 w-auto cursor-pointer"
                      />
                    </Link>
                    <Link
                      to="/admin/dashboards/backlinks-search"
                      className="ml-4 text-lg font-semibold tracking-tight"
                    >
                      LinkPricer
                    </Link>
                  </div>
                  <div className="mt-2 flex justify-end">
                    <p className="text-[14px] font-semibold text-navy-700 dark:text-white">
                      BETA version
                    </p>
                  </div>
                </div>
                <div
                  className={`font-poppins text-[26px] font-bold uppercase text-navy-700 dark:text-white ${
                    mini === false
                      ? 'hidden'
                      : mini === true && hovered === true
                      ? 'hidden'
                      : 'block'
                  }`}
                >
                  <img src={logo} alt="icon" />
                </div>
              </div>
              <div className="mb-2 mt-[58px] h-px bg-gray-200 dark:bg-white/10" />
              {/* Nav item */}
              <ul>
                <Links
                  mini={mini}
                  hovered={hovered}
                  routes={routes}
                  onClose={onClose}
                />
              </ul>
            </div>
          </div>
        </Scrollbars>
      </Card>
    </div>
  );
}

export default SidebarHorizon;
