import React, { useState, useEffect, useContext } from 'react';
import TopUp from './components/TopUp';
import TopUpHistory from './components/TopUpHistory';
import OrderHistory from './components/OrderHistory';
import { getUserId } from 'api';
import { CreditsContext } from 'contexts/CreditsContext';
import Pricing from './components/Pricing';
import { get } from 'api';
import moment from 'moment';

const Credits = () => {
  const { currentBalance, refreshBalance } = useContext(CreditsContext);
  const [currentView, setCurrentView] = useState('pricing');
  const userId = getUserId();
  const [isPlanActive, setIsPlanActive] = useState(false);

  const fetchUserSubscription = async () => {
    const response = await get(`/api/subscriptions/user-plan/${userId}`);
    const data = await response.json();
    setIsPlanActive(data[0]?.planEndDate >= moment().format('YYYY-MM-DD'));
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const sessionId = urlParams.get('session_id');

    if (sessionId) {
      setCurrentView('topup');
    }
  }, []);

  useEffect(() => {
    refreshBalance(userId);
    fetchUserSubscription();
  }, [userId]);

  useEffect(() => {
    fetchUserSubscription();
  }, [currentView]);

  return (
    <div className="h-full w-full rounded-[20px] pt-7 md:px-3">
      <div className="!z-5 relative flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-2 shadow-3xl shadow-shadow-100 md:w-[100%] md:!p-5">
        <p className="text-lg font-bold text-navy-700 dark:text-white">
          Your current credits balance
        </p>
        <div className="mt-1 flex items-center justify-start">
          <p className="flex items-center text-2xl font-bold text-navy-700 dark:text-white">
            {currentBalance}
          </p>
        </div>
        <div className="mt-5 flex flex-col items-center justify-start md:flex-row">
          <div
            onClick={() => setCurrentView('pricing')}
            className={`
                ${
                  currentView === 'pricing'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md cursor-pointer font-bold  hover:border-b-2 hover:border-navy-700`}
          >
            Subscription Plans
          </div>
          {isPlanActive && (
            <div
              onClick={() => setCurrentView('topup')}
              className={`
                ${
                  currentView === 'topup'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md mt-2 cursor-pointer font-bold  hover:border-b-2 hover:border-navy-700 md:ml-12 md:mt-0`}
            >
              Add more credits
            </div>
          )}
          <div
            onClick={() => setCurrentView('topupHistory')}
            className={`
                ${
                  currentView === 'topupHistory'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md mt-2 cursor-pointer font-bold hover:border-b-2 hover:border-navy-700 md:ml-12 md:mt-0`}
          >
            Top Ups History
          </div>
          {/* <div
            onClick={() => setCurrentView('ordersHistory')}
            className={`
                ${
                  currentView === 'ordersHistory'
                    ? 'border-b-2 border-navy-700 text-navy-700'
                    : 'text-gray-400'
                }
            text-md mt-4 cursor-pointer font-bold hover:border-b-2 hover:border-navy-700 md:ml-12 md:mt-0`}
          >
            Orders History
          </div> */}
        </div>
        <div className="mt-5 flex w-full">
          {currentView === 'pricing' && <Pricing />}
          {currentView === 'topup' && isPlanActive && <TopUp />}
          {currentView === 'topupHistory' && <TopUpHistory />}
          {currentView === 'ordersHistory' && <OrderHistory />}
        </div>
      </div>
    </div>
  );
};

export default Credits;
