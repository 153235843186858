import React, { useContext, useState } from 'react';
import CryptoJS from 'crypto-js';
import Select from 'react-select';
import { toastError } from 'utils/toasts';
import { FiSearch } from 'react-icons/fi';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
  useExpanded,
} from 'react-table';
import TooltipHorizon from 'components/tooltip';
import { post, getUserId } from 'api';
import moment from 'moment';
import * as FileSaver from 'file-saver';
import XLSX from 'sheetjs-style';
import { CreditsContext } from 'contexts/CreditsContext';
import { IoEyeOffOutline, IoEyeOutline } from 'react-icons/io5';
import Spinner from 'components/loaders/Spinner';
import { referralUrls } from 'constants/marketplaces';
import { put } from 'api';

const nichesOptions = [
  { value: '', label: 'General' },
  { value: 'Gambling', label: 'iGaming' },
  { value: 'Adult', label: 'Adult' },
  { value: 'CBD', label: 'CBD' },
];

const styles = {
  option: (styles, { data, isDisabled, isFocused, isSelected }) => {
    return {
      ...styles,
      backgroundColor: isDisabled
        ? null
        : isSelected
        ? '#f5f5f5'
        : isFocused
        ? '#ccc'
        : null,
      color: isDisabled ? '#ccc' : '#4B5563',
      cursor: isDisabled ? 'not-allowed' : 'pointer',
      fontSize: '14px',
      lineHeight: '20px',
      fontFamily: 'DM Sans',
      fontWeight: '500',
      '&:active': {
        backgroundColor: '#f5f5f5',
      },
    };
  },
};

function SearchBulkDomains(props) {
  const { columns } = props;
  const { currentBalance, refreshBalance } = useContext(CreditsContext);
  const [bulkData, setBulkData] = useState([]);
  const [bulkValue, setBulkValue] = useState('');
  const [submitting, setSubmitting] = useState(false);
  const userId = getUserId();
  const [dataLoading, setDataLoading] = useState(false);
  const [allowedNiche, setAllowedNiche] = useState(null);

  const handleSearch = async (e) => {
    setDataLoading(true);
    if (Number(currentBalance) <= 0) {
      toastError(
        'This action requires active subscription plan and available credits. Please top up your credits to continue.',
        3500,
      );
      setDataLoading(false);
      return;
    }
    e.preventDefault();

    const domains = bulkValue.split(/[\n,]\s*/).map((line) => {
      try {
        const { hostname } = new URL(line.trim());
        return hostname.replace('www.', '');
      } catch (error) {
        // Handle invalid URL
        return line.trim().replace('www.', '');
      }
    });

    const body = {
      userId,
      domains,
    };
    const nicheValue = allowedNiche ? allowedNiche.value : null;

    try {
      const res = await post(
        `/api/domains/bulk-search?allowedNiche=${nicheValue}`,
        body,
      );
      const data = await res.json();
      const encryptedData = data.data;
      const bytes = CryptoJS.AES.decrypt(
        encryptedData,
        process.env.REACT_APP_CRYPTO_SECRET,
      );
      const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));

      if (!decryptedData) {
        setDataLoading(false);
        return;
      }

      const initialDomainsLength = domains.length;
      const finalDomainsLength = data.total;

      if (initialDomainsLength !== finalDomainsLength) {
        if (
          window.confirm(
            `${finalDomainsLength} out of ${initialDomainsLength} domains were found. ${finalDomainsLength} credits will be deducted from your account. Do you want to continue?`,
          )
        ) {
          const creditsBody = {
            userId,
            creditsToDeduct: finalDomainsLength,
          };
          await put(`/api/domains/bulk-search-credits`, creditsBody);
        } else {
          setDataLoading(false);
          return;
        }
      } else {
        if (
          window.confirm(
            `All ${finalDomainsLength} domains were found. ${finalDomainsLength} credits will be deducted from your account. Do you want to continue?`,
          )
        ) {
          const creditsBody = {
            userId,
            creditsToDeduct: finalDomainsLength,
          };
          await put(`/api/domains/bulk-search-credits`, creditsBody);
        } else {
          setDataLoading(false);
          return;
        }
      }

      setBulkData(decryptedData);
      refreshBalance();
    } catch (error) {
      console.error('Error during bulk search:', error);
      toastError(
        'An error occurred during the bulk search. Please try again.',
        3500,
      );
    } finally {
      setDataLoading(false);
    }
  };

  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  function s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  const handleExport = async () => {
    setSubmitting(true);
    const fileName =
      'Bulk Domain Search Results - ' + moment().format('YYYY-MM-DD');
    let excelData;

    // Determine the maximum number of marketplaces
    // const maxMarketplaces = Math.max(
    //   ...bulkData.map((report) => report.marketplaces.length),
    // );

    excelData = bulkData.map((report) => {
      // const marketplaces = report.marketplaces || [];
      // const marketplaceData = {};

      // // Add columns for each marketplace's name and price
      // for (let i = 0; i < maxMarketplaces; i++) {
      //   marketplaceData[`Marketplace Name ${i + 1}`] = marketplaces[i]
      //     ? marketplaces[i].name
      //     : 'n/A';
      //   marketplaceData[`Marketplace Price ${i + 1}`] = marketplaces[i]
      //     ? `€ ${
      //         marketplaces[i].name === 'app.linkjuice.market'
      //           ? marketplaces[i].price
      //           : 'n/A'
      //       }`
      //     : 'n/A';
      // }

      return {
        'Domain Name': report.domainName,
        'Domain Price': report.price,
        'Domain Rating': report.domainRating,
        'Organic Traffic':
          report.orgTraffic && Number(report.orgTraffic).toLocaleString(),
        'Organic Keywords':
          report.orgKeywords && Number(report.orgKeywords).toLocaleString(),
        'Referring Domains':
          report.refDomains && Number(report.refDomains).toLocaleString(),
        'Linked Domains':
          report.linkedDomains && Number(report.linkedDomains).toLocaleString(),
        Country: report.country || 'n/A',
        Language: report.language || 'n/A',
        Category: report.category || 'n/A',
        // ...marketplaceData,
      };
    });

    const ws = XLSX.utils.json_to_sheet(excelData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, 'Bulk Search Results');
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
    const data = new Blob([s2ab(excelBuffer)], { type: fileType });
    FileSaver.saveAs(data, fileName + fileExtension);
    setSubmitting(false);
  };

  const tableInstance = useTable(
    {
      columns,
      data: bulkData,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <div className="container mx-auto mt-8 flex h-full min-h-[100vh] flex-col px-4 md:flex-row md:justify-between">
      <div className="mt-8 h-full md:mt-0 md:w-[21%]">
        <div className="flex flex-col">
          <div className="mb-4 w-full">
            <div className="mt-1 w-full">
              <Select
                options={nichesOptions}
                value={allowedNiche}
                onChange={(selectedOption) => setAllowedNiche(selectedOption)}
                placeholder="Select allowed niche"
                styles={styles}
              />
            </div>
          </div>
          <textarea
            className="h-[200px] w-full rounded-[10px] border border-gray-200 bg-white p-4 text-sm text-gray-600 scrollbar-thin scrollbar-thumb-gray-300"
            placeholder="Paste your bulk domains here"
            onChange={(e) => {
              const input = e.target.value;
              const lines = input.split(/[\n,]\s*/);
              const cleanedDomains = lines.map((line) => {
                try {
                  const { hostname } = new URL(line.trim());
                  return hostname.replace('www.', '');
                } catch (error) {
                  // Handle invalid URL
                  return line.trim().replace('www.', '');
                }
              });
              setBulkValue(cleanedDomains.join('\n'));
            }}
          ></textarea>
          <button
            className="mb-4 mt-4 flex h-9 w-full items-center justify-center rounded-full bg-brand-500 p-4 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700"
            onClick={(e) => handleSearch(e)}
          >
            {dataLoading ? (
              <>
                <Spinner width={6} height={6} ml={0} />
              </>
            ) : (
              <>
                Search{' '}
                <FiSearch className="mx-2 h-4 w-4 !text-white dark:!text-white" />
              </>
            )}
          </button>
        </div>
      </div>
      <div className="h-full md:w-[78%]">
        {bulkData.length > 0 && (
          <button
            disabled={submitting || bulkData.length === 0}
            onClick={() => handleExport()}
            className="flex h-9 w-full items-center justify-center rounded-full bg-brand-500 p-4 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
          >
            Export to Excel
          </button>
        )}
        {/* table */}
        <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-visible">
          <table {...getTableProps()} className="w-full">
            <thead className="w-full">
              {headerGroups.map((headerGroup, index) => (
                <tr
                  className=" items-center border-b border-gray-200 dark:!border-white/10"
                  {...headerGroup.getHeaderGroupProps()}
                  key={index}
                >
                  {headerGroup.headers.map((column, j) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className={`${
                        j === 0 || j === 3 || j === 4 || j === 5
                          ? 'min-w-[120px]'
                          : 'min-w-auto'
                      } px-4 pb-[10px] text-start text-xs text-gray-600`}
                    >
                      <TooltipHorizon
                        extra="max-w-[200px] overflow-y-auto"
                        trigger={
                          <p className="cursor-pointer overflow-hidden overflow-ellipsis whitespace-nowrap">
                            {column.render('Header') === 'DOMAIN RATING'
                              ? 'DR'
                              : column.render('Header') === 'ORGANIC TRAFFIC'
                              ? 'OT'
                              : column.render('Header') === 'ORGANIC KEYWORDS'
                              ? 'OK'
                              : column.render('Header') === 'REFERRING DOMAINS'
                              ? 'RD'
                              : column.render('Header') === 'LINKED DOMAINS'
                              ? 'LD'
                              : column.render('Header')}
                          </p>
                        }
                        content={
                          <p className="text-sm font-bold text-navy-700 dark:text-white">
                            {column.render('Header')}
                          </p>
                        }
                        placement="top"
                      />
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody className="w-full" {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell, index) => {
                        if (cell.column.id === 'expander') {
                          return (
                            <td
                              className="flex items-start px-4 py-[16px]"
                              {...cell.getCellProps({
                                style: {
                                  width: '50px',
                                },
                              })}
                              key={index}
                            >
                              <span
                                className="flex items-center justify-center"
                                {...row.getToggleRowExpandedProps({
                                  title: row.isExpanded
                                    ? 'View less'
                                    : 'View more',
                                })}
                              >
                                {row.isExpanded ? (
                                  <IoEyeOffOutline className="h-6 w-6" />
                                ) : (
                                  <IoEyeOutline className="h-6 w-6" />
                                )}
                              </span>
                            </td>
                          );
                        }
                        return (
                          <td
                            className="mt-[20px] px-4 py-[16px]"
                            {...cell.getCellProps({
                              style: {
                                width: cell.column.width
                                  ? `${cell.column.width}px`
                                  : 'auto',
                              },
                            })}
                            key={index}
                          >
                            {cell.render('Cell')}
                          </td>
                        );
                      })}
                    </tr>

                    {row.isExpanded && (
                      <tr className="border-b border-t border-gray-200 dark:!border-white/10">
                        <td colSpan={1}>
                          <div className="flex flex-col px-4 py-[16px]">
                            <TooltipHorizon
                              extra="max-w-[200px] overflow-y-auto"
                              trigger={
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                  OK
                                </p>
                              }
                              content={
                                <p className="text-sm font-bold text-navy-700">
                                  Organic Keywords
                                </p>
                              }
                              placement="top"
                            />
                            <span>
                              {Number(
                                row.original.orgKeywords,
                              ).toLocaleString()}
                            </span>
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div className="flex flex-col px-4 py-[16px]">
                            <TooltipHorizon
                              extra="max-w-[200px] overflow-y-auto"
                              trigger={
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                  RD
                                </p>
                              }
                              content={
                                <p className="text-sm font-bold text-navy-700">
                                  Referring Domains
                                </p>
                              }
                              placement="top"
                            />
                            <span>
                              {Number(row.original.refDomains).toLocaleString()}
                            </span>
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div className="flex flex-col px-4 py-[16px]">
                            <TooltipHorizon
                              extra="max-w-[200px] overflow-y-auto"
                              trigger={
                                <p className="overflow-hidden overflow-ellipsis whitespace-nowrap">
                                  LD
                                </p>
                              }
                              content={
                                <p className="text-sm font-bold text-navy-700">
                                  Linked Domains
                                </p>
                              }
                              placement="top"
                            />
                            <span>
                              {Number(
                                row.original.linkedDomains,
                              ).toLocaleString()}
                            </span>
                          </div>
                        </td>
                        {/* <td colSpan={1} /> */}
                        <td colSpan={1}>
                          <div className="px-4 py-[16px]">
                            {row.original.marketplaces.map(
                              (marketplace, index) => (
                                <div key={index}>
                                  <span>€{marketplace.price}</span>
                                </div>
                              ),
                            )}
                          </div>
                        </td>
                        <td colSpan={1}>
                          <div className="px-4 py-[16px]">
                            {row.original.marketplaces.map(
                              (marketplace, index) => {
                                const referralUrl =
                                  referralUrls[marketplace.name] ||
                                  marketplace.name;
                                return (
                                  <div key={index}>
                                    <a
                                      href={`https://${referralUrl}`}
                                      target="_blank"
                                      rel="noreferrer"
                                      className="cursor-pointer text-blue-500 hover:underline"
                                    >
                                      {marketplace.name}
                                    </a>
                                  </div>
                                );
                              },
                            )}
                          </div>
                        </td>
                      </tr>
                    )}
                  </React.Fragment>
                );
              })}
            </tbody>
          </table>
        </div>
        {/* pagination */}
        <div className="mt-2 flex h-[80px] w-full items-center justify-between md:px-6">
          {/* left side */}
          <div className="flex items-center gap-2">
            <select
              value={pageSize}
              onChange={(e) => setPageSize(Number(e.target.value))}
              className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
              name=""
              id=""
            >
              <option value="5">5</option>
              <option value="10">10</option>
              <option value="15">15</option>
              <option value="20">20</option>
              <option value="50">50</option>
            </select>
            <p className="text-sm text-gray-700">
              Showing {page.length} of {rows.length || 0} results
            </p>
          </div>
          {/* right side */}
          <div className="flex w-[40%] items-center gap-2 md:w-auto">
            <button
              onClick={() => previousPage()}
              className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            >
              <MdChevronLeft />
            </button>

            <p className="text-sm text-gray-700">
              {state.pageIndex + 1} of{' '}
              {bulkData.length === 0
                ? 1
                : Math.ceil(bulkData.length / pageSize)}
            </p>

            <button
              onClick={() => nextPage()}
              className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
            >
              <MdChevronRight />
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SearchBulkDomains;
