import { useState, useEffect } from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import TextField from 'components/fields/TextField';
import { put, getUserId } from 'api';

const Information = ({ user }) => {
  const { firstName, lastName, email, jobTitle, about } = user;
  const [firstNameState, setFirstNameState] = useState(firstName);
  const [lastNameState, setLastNameState] = useState(lastName);
  const [emailState, setEmailState] = useState(email);
  const [jobTitleState, setJobTitleState] = useState(jobTitle);
  const [aboutState, setAboutState] = useState(about);

  const handleFirstNameChange = (e) => {
    const value = e.target.value;
    const regex = /[^\p{L}\s]/u;

    if (!regex.test(value)) {
      setFirstNameState(value);
    }
  };

  const handleLastNameChange = (e) => {
    const value = e.target.value;
    const regex = /[^\p{L}\s]/u;

    if (!regex.test(value)) {
      setLastNameState(value);
    }
  };

  const handleEmailChange = (e) => {
    setEmailState(e.target.value);
  };

  const handleJobTitleChange = (e) => {
    const value = e.target.value;
    const regex = /[^\p{L}\s]/u;

    if (!regex.test(value)) {
      setJobTitleState(value);
    }
  };

  const handleAboutChange = (e) => {
    setAboutState(e.target.value);
  };

  useEffect(() => {
    setFirstNameState(firstName);
    setLastNameState(lastName);
    setEmailState(email);
    setJobTitleState(jobTitle);
    setAboutState(about);
  }, [firstName, lastName, email, jobTitle, about]);

  const handleUpdate = async () => {
    const userId = getUserId();
    const data = {
      firstName: firstNameState,
      lastName: lastNameState,
      email: emailState,
      jobTitle: jobTitleState,
      about: aboutState,
    };
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailState && !emailRegex.test(emailState)) {
      toastError('Please enter a valid email address!');
      return;
    }
    await put(`/api/user/${userId}`, data).then((res) => {
      if (res.status === 200) {
        toastSuccess('Account information updated successfully!');
      } else {
        toastError('Something went wrong!');
      }
    });
  };

  return (
    <Card extra={'w-full !p-5'}>
      {/* Header */}
      <div className="w-full px-[8px]">
        <h4 className="text-xl font-bold text-navy-700 dark:text-white">
          Account Settings
        </h4>
        <p className="mt-1 text-base text-gray-600">
          Here you can change user account information
        </p>
      </div>
      {/* inputs */}
      <div className="mb-2 mt-7 grid gap-3 md:grid-cols-2">
        <InputField
          label="First Name"
          placeholder="John"
          id="firstName"
          type="text"
          value={firstNameState}
          onChange={handleFirstNameChange}
        />
        <InputField
          label="Last Name"
          placeholder="Mihalache"
          id="lastName"
          type="text"
          value={lastNameState}
          onChange={handleLastNameChange}
        />
        <InputField
          label="Email Address"
          placeholder="@gmail.com"
          id="email"
          type="text"
          value={emailState}
          onChange={handleEmailChange}
        />
        <InputField
          label="Job Title"
          placeholder="CTO"
          id="jobTitle"
          type="text"
          extra="mb-2"
          value={jobTitleState}
          onChange={handleJobTitleChange}
        />
      </div>
      {/* full width inputs */}
      <TextField
        label="About Me"
        placeholder="Tell something about yourself in 150 characters!"
        id="textarea"
        cols="30"
        rows="7"
        value={aboutState}
        onChange={handleAboutChange}
      />
      {/* save button */}
      <div className="mt-4 flex w-full justify-end">
        <button
          onClick={() => handleUpdate()}
          className="w-full rounded-xl bg-brand-500 px-4 py-2 text-base font-medium text-white transition duration-200 hover:cursor-pointer hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-auto"
        >
          Save Changes
        </button>
      </div>
    </Card>
  );
};

export default Information;
