import { useEffect, useMemo, useState } from 'react';
import Card from 'components/card';
import SearchByDomain from './components/SearchByDomain';
import SearchByKeyword from './components/SearchByKeyword';
import gamblingIcon from 'assets/svg/gambling.svg';
import adultIcon from 'assets/svg/adult.svg';
import loanIcon from 'assets/svg/loan.svg';
import datingIcon from 'assets/svg/dating.svg';
import cbdIcon from 'assets/svg/cbd.svg';
import cryptoIcon from 'assets/svg/crypto.svg';
import TooltipHorizon from 'components/tooltip';
import MetricsDropdown from '../components/MetricsDropdown';
import { getUserId } from 'api';
import { get } from 'api';
import NewcomerModal from './components/NewcomerModal';

const niches = [
  {
    name: 'Gambling',
    icon: gamblingIcon,
    tooltip: 'Gambling content is allowed',
  },
  {
    name: 'Adult',
    icon: adultIcon,
    tooltip: 'Adult content is allowed',
  },
  {
    name: 'Loan',
    icon: loanIcon,
    tooltip: 'Loan content is allowed',
  },
  {
    name: 'Dating',
    icon: datingIcon,
    tooltip: 'Dating content is allowed',
  },
  { name: 'CBD', icon: cbdIcon, tooltip: 'CBD content is allowed' },
  {
    name: 'Crypto',
    icon: cryptoIcon,
    tooltip: 'Crypto content is allowed',
  },
];

const BacklinksList = () => {
  const [currentView, setCurrentView] = useState('domain');
  const currencySymbol = localStorage.getItem('currency');
  const [isModalOpen, setIsModalOpen] = useState(false);
  const userId = getUserId();

  useEffect(() => {
    const fetchNewcomerPopup = async () => {
      const response = await get(`/api/misc/newcomer-popup/${userId}`);
      const data = await response.json();

      if (data.showPopup) {
        setIsModalOpen(true);
      }
    };

    fetchNewcomerPopup();
  }, [userId]);

  const columns = useMemo(
    () => [
      {
        Header: 'DOMAIN NAME',
        accessor: 'domainName',
        Cell: ({ value }) => (
          <div className="font-medium text-navy-700 dark:text-white">
            <MetricsDropdown domainName={value} />
          </div>
        ),
      },
      {
        Header: 'DOMAIN RATING',
        accessor: 'domainRating',
        Cell: ({ value }) => {
          const formattedValue =
            value === '-' ||
            value === null ||
            value === 'N/A' ||
            value === '' ||
            value === 'Not Provided' ||
            value === 'Notprovided' ||
            value === 'Not Available'
              ? '-'
              : Number(value).toLocaleString();
          return (
            <div className="font-medium text-navy-700 dark:text-white">
              {formattedValue}
            </div>
          );
        },
      },
      {
        Header: 'ORGANIC TRAFFIC',
        accessor: 'orgTraffic',
        Cell: ({ value }) => {
          const formattedValue =
            value === '-' ||
            value === null ||
            value === 'N/A' ||
            value === '' ||
            value === 'Not Provided' ||
            value === 'Notprovided' ||
            value === 'Not Available' ||
            value === 'Nodata'
              ? '-'
              : Number(value).toLocaleString();
          return (
            <div className="font-medium text-navy-700 dark:text-white">
              {formattedValue}
            </div>
          );
        },
      },
      // {
      //   Header: 'ALLOWED NICHE',
      //   accessor: 'allowedNiche',
      //   width: 200,
      //   Cell: ({ value }) => {
      //     return (
      //       <div className="flex">
      //         {niches.map(
      //           (niche) =>
      //             value?.includes(niche.name) && (
      //               <TooltipHorizon
      //                 key={niche.name}
      //                 extra="max-w-[250px] overflow-y-auto"
      //                 trigger={
      //                   <img
      //                     src={niche.icon}
      //                     alt={niche.name}
      //                     className="mr-1.5 h-5 w-5"
      //                   />
      //                 }
      //                 content={
      //                   <p className="text-sm font-bold text-navy-700">
      //                     {niche.tooltip}
      //                   </p>
      //                 }
      //                 placement="top"
      //               />
      //             ),
      //         )}
      //         {(value === '' || value === null) && '-'}
      //       </div>
      //     );
      //   },
      // },
      {
        Header: 'PRICE',
        accessor: 'price',
        Cell: ({ value }) => (
          <div className="font-medium text-navy-700 dark:text-white">
            {value ? currencySymbol + value.toLocaleString() : '-'}
          </div>
        ),
      },
      {
        Header: 'MARKETPLACE',
        accessor: 'marketplace',
        width: 250,
        Cell: ({ value }) => (
          <a
            href={`https://${value}`}
            rel="noreferrer noopener"
            target="_blank"
            className="text-blue-500 hover:underline dark:text-blue-400"
          >
            {value}
          </a>
        ),
      },
      {
        Header: 'ACTION',
        id: 'expander',
      },
    ],
    [currencySymbol],
  );

  return (
    <Card extra={'w-full h-full bg-white mt-3'}>
      <div className="container mx-auto mt-5 flex h-full flex-col px-4 md:flex-row md:justify-between">
        {/* <div className="mt-4 flex md:mt-0">
          <button
            onClick={() => setCurrentView('domain')}
            className={`${
              currentView === 'domain'
                ? 'border-blue-500 text-blue-500'
                : 'border-gray-200 text-gray-500'
            } border-b-2 px-4 py-2`}
          >
            Search by domain
          </button>
          <button
            onClick={() => setCurrentView('keyword')}
            className={`${
              currentView === 'keyword'
                ? 'border-blue-500 text-blue-500'
                : 'border-gray-200 text-gray-500'
            } border-b-2 px-4 py-2`}
          >
            Search by keyword
          </button>
        </div> */}
      </div>
      {currentView === 'domain' && <SearchByDomain columns={columns} />}
      {currentView === 'keyword' && <SearchByKeyword columns={columns} />}
      <NewcomerModal
        isModalOpen={isModalOpen}
        setIsModalOpen={setIsModalOpen}
      />
    </Card>
  );
};

export default BacklinksList;
