import React, { useMemo, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { FiSearch } from 'react-icons/fi';
import { IoMdFunnel } from 'react-icons/io';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { get, getUserId } from 'api';
import TooltipHorizon from 'components/tooltip';

const TopUpHistory = () => {
  const navigate = useNavigate();
  const [data, setData] = useState([]);
  const currencySymbol = localStorage.getItem('currency');

  useEffect(() => {
    const fetchHistory = async () => {
      const userId = getUserId();
      const response = await get(`/api/credits/transactions/${userId}`);
      const data = await response.json();
      if (data.length === 0) {
        return;
      }
      setData(data);
    };
    fetchHistory();
  }, []);

  const columns = useMemo(
    () => [
      {
        Header: 'Date',
        accessor: 'createdAt',
        Cell: ({ value }) => new Date(value).toISOString().slice(0, 10),
      },
      {
        Header: 'Company Name',
        accessor: 'companyName',
      },
      {
        Header: 'Credits Added',
        accessor: 'creditsAdded',
        Cell: ({ value }) => <span className="text-green-500">+{value}</span>,
      },
      {
        Header: 'Amount Paid',
        accessor: 'amountPaid',
        Cell: ({ value }) =>
          value ? (
            <span className="text-red-500">
              -{currencySymbol}
              {value.toLocaleString()}
            </span>
          ) : (
            ''
          ),
      },
      {
        Header: 'Invoice',
        accessor: 'invoiceNumber',
        Cell: ({ value }) => (
          <TooltipHorizon
            trigger={
              <span
                className="cursor-pointer text-blue-500 underline"
                onClick={() =>
                  navigate(`/admin/account/invoice`, {
                    state: { invoiceNumber: value },
                  })
                }
              >
                {value}
              </span>
            }
            content={
              <p className="text-sm font-bold text-navy-700 dark:text-white">
                View Invoice
              </p>
            }
            placement="top"
          />
        ),
      },
    ],
    [currencySymbol],
  );

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <div className="flex w-full flex-col">
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/3"
      >
        <div className="flex h-9 w-full flex-grow items-center  rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr
                {...headerGroup.getHeaderGroupProps()}
                key={index}
                className="w-full"
              >
                {headerGroup.headers.map((column, index) => {
                  return (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 text-center dark:!border-navy-700"
                      key={index}
                    >
                      <div className="text-sm font-bold tracking-wide text-gray-600">
                        <div className="flex items-center justify-center gap-2">
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsChevronUp className="mr-1 inline h-4 w-4" />
                            ) : (
                              <BsChevronDown className="mr-1 inline h-4 w-4" />
                            )
                          ) : (
                            <IoMdFunnel className="mr-1 inline h-4 w-4" />
                          )}
                        </div>
                      </div>
                    </th>
                  );
                })}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.length === 0 && (
              <tr className="border-b border-gray-200 dark:!border-white/10">
                <td
                  colSpan="5"
                  className="border px-4 py-2 text-center text-gray-600 dark:!border-white/10"
                >
                  No transactions yet
                </td>
              </tr>
            )}
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td
                        {...cell.getCellProps()}
                        className="border px-4 py-2 text-center"
                      >
                        {cell.render('Cell')}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(data.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
};

export default TopUpHistory;
