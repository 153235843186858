import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import routes from 'routes';
import Footer from 'components/footer/Footer';
import { Portal } from '@chakra-ui/portal';
import Navbar from 'components/navbar';
import LandingNavbar from 'components/navbar/LandingNavbar';

export default function Landing() {
  const getRoutes = (routes) => {
    return routes.map((prop, key) => {
      if (prop.layout === '/landing') {
        return (
          <Route path={`${prop.path}`} element={prop.component} key={key} />
        );
      }
      if (prop.collapse) {
        return getRoutes(prop.items);
      }
      return null;
    });
  };
  document.documentElement.dir = 'ltr';
  return (
    <div>
      <div className="h-full w-full font-dm dark:bg-navy-900">
        <main className={`mx-auto min-h-screen`}>
          <LandingNavbar />
          <Routes>
            {getRoutes(routes)}
            <Route path="/" element={<Navigate to="/" replace />} />
          </Routes>
        </main>
        <Footer />
      </div>
    </div>
  );
}
