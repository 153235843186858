import React, { useMemo } from 'react';
import { FiSearch } from 'react-icons/fi';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';

function SearchTableOrders(props) {
  const { columnsData, tableData } = props;
  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => tableData, [tableData]);
  const currencySymbol = localStorage.getItem('currency');

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <div className="h-full w-full">
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white p-[8px] pt-[18px] shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:ml-3 md:w-[400px]"
      >
        <div className="flex h-9 w-[400px] flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {/* table */}
      <div className="mt-11 h-full w-full overflow-x-scroll xl:overflow-hidden">
        <table {...getTableProps()} className="w-full">
          <thead className="w-full">
            {headerGroups.map((headerGroup, index) => (
              <tr
                className=" items-center border-b border-gray-200 dark:!border-white/10"
                {...headerGroup.getHeaderGroupProps()}
                key={index}
              >
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className=" px-4 pb-[10px] text-start text-xs text-gray-600"
                  >
                    {column.render('Header')}
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody className="w-full" {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  className="border-b border-gray-200 dark:!border-white/10"
                  {...row.getRowProps()}
                  key={index}
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'PRODUCT NAME') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'EMAIL') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'DATE') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'STATUS ORDER') {
                      data = (
                        <div
                          className={`flex h-7 w-[110px] items-center justify-center text-sm ${
                            cell.value === 'Completed'
                              ? 'bg-green-100 dark:bg-green-50'
                              : 'bg-red-100 dark:bg-red-50'
                          } rounded-[10px] text-base font-bold `}
                        >
                          <div
                            className={`${
                              cell.value === 'Completed'
                                ? 'text-green-500 '
                                : 'text-red-500'
                            } uppercase `}
                          >
                            {cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'TOTAL PRICE') {
                      data = (
                        <div className="font-medium text-navy-700 dark:text-white">
                          {currencySymbol}
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'ORDER ACTIONS') {
                      data = (
                        <div
                          className="text-sm font-medium text-brand-500 underline hover:cursor-pointer dark:text-white"
                          id={cell.value}
                        >
                          Edit order
                        </div>
                      );
                    }
                    return (
                      <td
                        className=" mt-[20px] px-4 py-[16px]"
                        {...cell.getCellProps()}
                        key={index}
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-2 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(tableData.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </div>
  );
}

export default SearchTableOrders;
