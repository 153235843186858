import React from 'react';
import logo from 'assets/img/logo/logo.png';
import { useNavigate } from 'react-router-dom';

const LandingNavbar = () => {
  const navigate = useNavigate();

  const handleLogin = () => {
    navigate('/auth/login');
  };
  return (
    <nav className="flex items-center justify-between bg-blue-500 bg-gradient-to-br from-brand-400 to-brand-600 bg-cover bg-no-repeat p-6">
      <div className="mr-6 flex flex-shrink-0 items-center text-white">
        <img
          src={logo}
          alt="Logo"
          className="h-8 w-auto cursor-pointer"
          onClick={() => (window.location.href = 'https://linkpricer.com')}
        />
        <span className="ml-4 text-lg font-semibold tracking-tight">
          LinkPricer
        </span>
      </div>
      <div>
        <button
          className="hover:border-transparent inline-block rounded border border-white px-4 py-2 text-sm leading-none text-white hover:bg-white hover:text-blue-500 lg:mt-0"
          onClick={handleLogin}
        >
          Login
        </button>
      </div>
    </nav>
  );
};

export default LandingNavbar;
