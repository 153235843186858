import React, { useState } from 'react';
import Earn from './components/Earn';
import Invite from './components/Invite';
// Assets
import {
  MdAttachMoney,
  MdCreditCard,
  MdOutlineShoppingBasket,
  MdPerson,
  MdShowChart,
} from 'react-icons/md';
import tableDataReferrals from './variables/tableDataReferrals.json';
import { columnsDataReferrals } from './variables/columnsDataReferrals';

import MiniStatistics from 'components/card/MiniStatistics';
import ReferralsTable from './components/ReferralsTable';

const Referrals = () => {
  const [totalOrders, setTotalOrders] = useState(0);
  const [totalOrderPrice, setTotalOrderPrice] = useState(0);
  const [totalProfit, setTotalProfit] = useState(0);
  const [averageValue, setAverageValue] = useState(0);
  const [totalReferrals, setTotalReferrals] = useState(0);
  const [referralPercentage, setReferralPercentage] = useState(0);

  return (
    <div className="mt-3 grid h-full w-full grid-cols-12 gap-5 rounded-[20px]">
      <div className="col-span-12 w-full 3xl:col-span-8">
        <Earn referralPercentage={referralPercentage} />
      </div>
      <div className="col-span-12 w-full 3xl:col-span-4">
        <Invite />
      </div>
      {/* Statistics */}
      <div className="z-0 col-span-12 grid h-full w-full grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-5">
        <MiniStatistics
          name="Your Profit"
          value={`$${totalProfit}` || '$0.00'}
          icon={<MdShowChart />}
          iconBg="bg-lightPrimary"
        />
        <MiniStatistics
          name="Total Referrals"
          value={totalReferrals || 0}
          icon={<MdPerson />}
          iconBg="bg-lightPrimary"
        />
        <MiniStatistics
          name="All Transactions Value"
          value={`$${totalOrderPrice}` || '$0.00'}
          icon={<MdAttachMoney />}
          iconBg="bg-lightPrimary"
        />
        <MiniStatistics
          name="Average Transaction Value"
          value={`$${isNaN(averageValue) ? 0 : averageValue}` || '$0.00'}
          icon={<MdCreditCard />}
          iconBg="bg-lightPrimary"
        />
        <MiniStatistics
          name="Total Referrals Transactions"
          value={totalOrders || 0}
          icon={<MdOutlineShoppingBasket />}
          iconBg="bg-lightPrimary"
        />
      </div>
      {/* Table */}
      <div className="col-span-12">
        <ReferralsTable
          tableData={tableDataReferrals}
          columnsData={columnsDataReferrals}
          setTotalOrders={setTotalOrders}
          setTotalOrderPrice={setTotalOrderPrice}
          setTotalProfit={setTotalProfit}
          setAverageValue={setAverageValue}
          setTotalReferrals={setTotalReferrals}
          setReferralPercentage={setReferralPercentage}
        />
      </div>
    </div>
  );
};

export default Referrals;
