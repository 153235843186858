import { useState } from 'react';
import Suggestion from './components/Suggestion';
import Card from 'components/card';
import { getUserId, post } from 'api';
import { toastSuccess } from 'utils/toasts';
import { toastError } from 'utils/toasts';

const SuggestFeature = () => {
  const [featureName, setFeatureName] = useState('');
  const [featureDescription, setFeatureDescription] = useState('');
  const userId = getUserId();

  const handleSubmit = async () => {
    const body = {
      featureName,
      featureDescription,
      userId,
    };
    try {
      await post('/api/misc/suggest-feature', body);
      setFeatureName('');
      setFeatureDescription('');
      toastSuccess('Feature suggestion submitted successfully');
    } catch (error) {
      toastError('Failed to submit feature suggestion');
    }
  };

  return (
    <div className="mt-3 h-full w-full">
      <div className="h-[350px] w-full rounded-[20px] bg-gradient-to-br from-brand-400 to-brand-600 md:h-[390px]" />
      <div className="mx-auto h-full w-11/12 md:w-2/3 md:px-3 3xl:w-7/12">
        <div className="-mt-[280px] w-full pb-10 md:-mt-[240px] md:px-[70px]">
          {/* <p className="text-center text-2xl font-bold text-white">
            Suggest a Feature
          </p> */}
        </div>

        <Card extra={'h-full mx-auto pb-3'}>
          <div className="rounded-[20px]">
            <Suggestion
              featureName={featureName}
              setFeatureName={setFeatureName}
              featureDescription={featureDescription}
              setFeatureDescription={setFeatureDescription}
            />
          </div>
          <div className="mt-4 flex justify-center">
            <button
              className="rounded-[10px] bg-brand-500 px-6 py-2 text-white hover:bg-brand-600"
              onClick={handleSubmit}
            >
              Submit
            </button>
          </div>
        </Card>
      </div>
    </div>
  );
};

export default SuggestFeature;
