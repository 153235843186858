import Card from 'components/card';

const PrivacyPolicyPage = () => {
  return (
    <Card extra={'mt-3 w-full md:w-1/2 h-full bg-white mx-auto'}>
      <div className="container mx-auto flex h-full min-h-[90vh] flex-col px-8 py-4">
        <h1 className="mt-5 text-center text-3xl font-bold">PRIVACY POLICY</h1>
        <p className="mt-3 text-center">
          Last updated: <span className="font-bold">15th July 2024</span>
        </p>
        <div className="mt-8 flex flex-col">
          <h2 className="text-lg font-bold">1. Introduction</h2>
          <p className="mt-2">
            Welcome to Linkpricer.com. We are committed to protecting the
            privacy and security of our users' data. This Privacy Policy
            outlines our practices concerning the collection, use, and
            disclosure of your information when you use our service.
            <br />
            By accessing or using our service, you agree to the collection and
            use of information in accordance with this policy.
          </p>
          <h2 className="mt-5 text-lg font-bold">2. Data Controller</h2>
          <p className="mt-2">
            Linkpricer, UAB is the data controller responsible for your personal
            data. We are registered in Lithuania under company code 306947938
            and our registered office is located at Krivių g. 5, LT-01204
            Vilnius. For any questions or requests concerning your personal
            data, or if you need to contact our Data Protection Officer (DPO),
            please email us at Butkus@linkpricer.com.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            3. What Information We Collect
          </h2>
          <p className="mt-2">
            We collect several types of information for various purposes to
            provide and improve our service to you. These include:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Personal Identification Information:</strong> Name, email
              address, telephone number, and physical address.
            </li>
            <li className="ml-5 mt-2">
              <strong>Login Data:</strong> Username, password, and other
              authentication information.
            </li>
            <li className="ml-5 mt-2">
              <strong>Usage Data:</strong> Information on how the service is
              accessed and used, such as your IP address, browser type, browser
              version, the pages of our service that you visit, the time and
              date of your visit, and the time spent on those pages.
            </li>
            <li className="ml-5 mt-2">
              <strong>Screen Recording Data:</strong> User interactions on
              LinkPricer.com, including mouse movements, clicks, and scrolling
              behavior, are collected via Hotjar. This data is anonymized and
              helps us enhance your experience on the platform.
            </li>
            <li className="ml-5 mt-2">
              <strong>Transactional Data:</strong> Details about transactions
              made on our site, including purchases of backlinks.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">
            4. How We Collect Your Data
          </h2>
          <p className="mt-2">We collect data through:</p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Direct Interactions:</strong> You may provide us personal
              data by filling in forms or by corresponding with us by post,
              phone, email, or otherwise.
            </li>
            <li className="ml-5 mt-2">
              <strong>Automated technologies or interactions:</strong> As you
              interact with our site, we may automatically collect usage data
              through cookies, Hotjar, and other similar technologies.
            </li>
            <li className="ml-5 mt-2">
              <strong>Third parties or publicly available sources:</strong> We
              may receive personal data about you from various third parties and
              public sources such as analytics providers or networking sites.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">
            5. Screen Recording with Hotjar
          </h2>
          <p className="mt-2">
            We use Hotjar, a third-party analytics service, to help us
            understand user interactions on LinkPricer.com. Hotjar records
            screen activity, including mouse movements, clicks, and scrolling
            behavior, to identify usability issues and improve platform
            functionality.
          </p>
          <p className="mt-2">
            All recorded data is anonymized and handled in compliance with data
            protection laws. If you prefer not to be recorded, you may opt out
            by visiting Hotjar’s opt-out page{' '}
            <a
              style={{ color: '#0077FF' }}
              href="https://www.hotjar.com/policies/do-not-track/"
            >
              here
            </a>
            .
          </p>
          <h2 className="mt-5 text-lg font-bold">
            6. Purpose of Data Processing
          </h2>
          <p className="mt-2">
            Your data is processed for the following purposes:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>To provide and maintain our service:</strong> Using the
              personal data you provide to manage your access to our service.
            </li>
            <li className="ml-5 mt-2">
              <strong>To notify you about changes to our service:</strong>{' '}
              Sending you information that is relevant to your use of the
              service.
            </li>
            <li className="ml-5 mt-2">
              <strong>To provide customer support:</strong> Offering you
              assistance with service-related issues or questions.
            </li>
            <li className="ml-5 mt-2">
              <strong>To gather analysis or valuable information:</strong> Using
              data to better understand your needs and provide you with enhanced
              service.
            </li>
            <li className="ml-5 mt-2">
              <strong>To monitor the usage of our service:</strong> Tracking how
              our service is used to help us improve user experience.
            </li>
            <li className="ml-5 mt-2">
              <strong>To detect, prevent, and address technical issues:</strong>{' '}
              Ensuring the security of our service and addressing any technical
              issues that arise.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">
            7. Legal Basis for Processing
          </h2>
          <p className="mt-2">
            The legal bases for processing your personal data include:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Consent:</strong> Specifically where you have provided
              consent to process your personal data for specific purposes.
            </li>
            <li className="ml-5 mt-2">
              <strong>Contractual Necessity:</strong> Processing data where it
              is necessary for the performance of a contract to which you are a
              party or to take steps at your request before entering into such a
              contract.
            </li>
            <li className="ml-5 mt-2">
              <strong>Legal Obligation:</strong> Processing your data where it
              is necessary for compliance with a legal obligation to which we
              are subject.
            </li>
            <li className="ml-5 mt-2">
              <strong>Legitimate Interests:</strong> Processing for the purposes
              of our legitimate interests or those of a third party, except
              where such interests are overridden by your interests or
              fundamental rights and freedoms.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">8. Data Recipients</h2>
          <p className="mt-2">
            We may share your personal data with selected third parties under
            the following circumstances:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Service Providers:</strong> To facilitate our service, we
              share data with providers of services such as hosting, cloud
              computing, customer support, and payment processing. These
              companies are authorized to use your personal information only as
              necessary to provide these services to us and are obligated to
              keep your information secure.
            </li>
            <li className="ml-5 mt-2">
              <strong>Professional Advisers:</strong> We may disclose your data
              to professional advisers including lawyers, bankers, auditors, and
              insurers where necessary in the course of the professional
              services that they render to us.
            </li>
            <li className="ml-5 mt-2">
              <strong>Compliance with Laws:</strong> We may disclose your data
              to a third party if we believe that disclosure is advisable or
              necessary to comply with the law, enforce or apply our conditions
              of use and other agreements, or protect the rights, property, or
              safety of Linkpricer.com, our employees, our users, or others.
              This may include exchanging information with other companies and
              organizations for fraud protection and credit risk reduction.
            </li>
            <li className="ml-5 mt-2">
              <strong>Business Transfers:</strong> In connection with any
              merger, sale of company assets, financing, or acquisition of all
              or a portion of our business by another company, your data may be
              transferred to the new owners so that we may continue the services
              provided.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">
            9. International Data Transfers
          </h2>
          <p className="mt-2">
            Your personal data may be transferred to, and processed in,
            countries other than the country in which you are resident. These
            countries may have data protection laws that are different to the
            laws of your country. Specifically, our servers are located in
            [country], and our third-party service providers and partners
            operate around the world. This means that when we collect your
            personal data we may process it in any of these countries. However,
            we have taken appropriate safeguards to require that your personal
            data will remain protected in accordance with this Privacy Policy.
            These include implementing the European Commission's Standard
            Contractual Clauses for transfers of personal information between
            our group companies and third-party service providers and partners,
            which require all parties to protect personal data they process from
            the EEA in accordance with European Union data protection law.
          </p>
          <h2 className="mt-5 text-lg font-bold">10. Data Retention Period</h2>
          <p className="mt-2">
            We retain personal data we collect from you where we have an ongoing
            legitimate business need to do so (for example, to provide you with
            a service you have requested or to comply with applicable legal,
            tax, or accounting requirements). When we have no ongoing legitimate
            business need to process your personal data, we will either delete
            or anonymize it or, if this is not possible (for example, because
            your personal data has been stored in backup archives), then we will
            securely store your personal data and isolate it from any further
            processing until deletion is possible.
          </p>
          <h2 className="mt-5 text-lg font-bold">11. Security Measures</h2>
          <p className="mt-2">
            We use appropriate technical and organizational measures to protect
            the personal data that we collect and process about you. The
            measures we use are designed to provide a level of security
            appropriate to the risk of processing your personal data. Specific
            measures include encrypting your personal data in transit and at
            rest, managing access to the data through robust access controls,
            and regularly testing and evaluating the effectiveness of our
            security measures.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            12. Your Data Protection Rights (GDPR)
          </h2>
          <p className="mt-2">
            Under data protection laws, you have certain rights related to the
            personal information we hold about you. These rights include:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Right to Access:</strong> You can request access to your
              personal information, including a copy of the personal data we
              hold about you.
            </li>
            <li className="ml-5 mt-2">
              <strong>Right to Rectification:</strong> If any of the personal
              information we hold about you is inaccurate or incomplete, you
              have the right to ask us to correct or complete it.
            </li>
            <li className="ml-5 mt-2">
              <strong>Right to Restrict Processing:</strong> Under certain
              conditions, you have the right to 'block' or suppress further use
              of your personal information. When processing is restricted, we
              can still store your information, but may not use it further.
            </li>
            <li className="ml-5 mt-2">
              <strong>Right to Data Portability:</strong> You have the right to
              obtain and reuse your personal data for your own purposes across
              different services. This means you can move, copy or transfer
              personal data easily from one IT environment to another in a safe
              and secure way, without hindrance to usability.
            </li>
            <li className="ml-5 mt-2">
              <strong>Right to Object:</strong> You have the right to object to
              the processing of your personal data in certain circumstances,
              including processing based on legitimate interests or the
              performance of a task in the public interest/exercise of official
              authority, direct marketing (including profiling), and processing
              for purposes of scientific/historical research and statistics.
            </li>
            <li className="ml-5 mt-2">
              <strong>Right to Withdraw Consent:</strong> Where we rely on
              consent to process your personal data, you have the right to
              withdraw that consent at any time, though this will not affect the
              lawfulness of the processing before your consent was withdrawn.
            </li>
          </ul>
          <p className="mt-2">
            You can exercise any of these rights by contacting us using the
            contact details provided under the "Contact Information" section of
            this policy.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            13. How to Exercise Your Rights
          </h2>
          <p className="mt-2">
            You can exercise any of your data protection rights by contacting
            our Data Protection Officer at Butkus@linkpricer.com or by mailing
            us at Krivių g. 5, LT-01204 Vilnius, Lithuania. Please provide as
            much detail as possible in your request to help us identify the
            information you wish to access, correct, or delete. We may need to
            verify your identity before fulfilling your request, which may
            include requesting additional information from you. We will respond
            to your request within one month of receipt of the request, unless
            the complexity and number of requests require an extension of this
            period, in which case we will inform you of the extension and the
            reasons for the delay.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            14. Consent and Its Withdrawal
          </h2>
          <p className="mt-2">
            When you provide personal data to us based on consent, you have the
            right to withdraw your consent at any time. However, the withdrawal
            of consent will not affect the lawfulness of processing based on
            consent before its withdrawal. To withdraw your consent, you may
            contact us at the details provided in the "Contact Information"
            section. Following withdrawal, we will cease processing your
            personal information unless legally required to continue to hold
            such data.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            15. Cookies and Similar Technologies
          </h2>
          <p className="mt-2">
            We use cookies and similar tracking technologies to track activity
            on our services and hold certain information. Cookies are files with
            a small amount of data which may include an anonymous unique
            identifier. We use cookies to enhance your experience on our
            platform, including providing personalized content and remembering
            your preferences. You have the option to accept or refuse these
            cookies and know when a cookie is being sent to your device. If you
            choose to refuse our cookies, you may not be able to use some
            portions of our Service.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            16. Automated Decision Making and Profiling
          </h2>
          <p className="mt-2">
            We do not use fully automated decision-making processes, including
            profiling, that have legal effects or similarly significantly affect
            you. Any decision-making processes that we use are subject to human
            intervention and are designed to prevent unfair or discriminatory
            outcomes.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            17. Data Protection Officer (DPO)
          </h2>
          <p className="mt-2">
            We have appointed a Data Protection Officer to ensure the protection
            of your personal data. Our DPO can be reached via email at
            Butkus@linkpricer.com or by post at our main business address. If
            you have any questions about how we handle your data, or if you need
            further assistance with respect to our Privacy Policy, please
            contact the DPO.
          </p>
          <h2 className="mt-5 text-lg font-bold">18. Third-party Links</h2>
          <p className="mt-2">
            Our Service includes links to external websites that are not managed
            or controlled by us. Clicking on those links may direct you to
            third-party websites. We strongly advise you to review the Privacy
            Policy of every site you visit. We do not control, and assume no
            responsibility for the content, privacy policies, or practices of
            any third-party sites or services.
          </p>
          <h2 className="mt-5 text-lg font-bold">19. Children’s Privacy</h2>
          <p className="mt-2">
            Our Service is not directed to individuals under the age of 16. We
            do not knowingly collect personal information from children under
            16. If we become aware that we have collected personal data from
            children without verification of parental consent, we take steps to
            remove that information from our servers. If you believe we might
            have any information from or about a child under 16, please contact
            us at the information provided in the Contact Us section.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            20. Changes to the Privacy Policy
          </h2>
          <p className="mt-2">
            We reserve the right to update or change our Privacy Policy at any
            time. We will notify you of any changes by posting the new Privacy
            Policy on this page and updating the "Last updated" date at the top
            of this document. We encourage you to review our Privacy Policy
            periodically for any changes. Changes to this Privacy Policy are
            effective when they are posted on this page.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            21. Complaints to Data Protection Authorities
          </h2>
          <p className="mt-2">
            You have the right to complain to a Data Protection Authority about
            our collection and use of your personal data. For more information,
            please contact your local data protection authority in the European
            Economic Area (EEA).
          </p>
          <h2 className="mt-5 text-lg font-bold">
            22. Impact of Not Providing Data
          </h2>
          <p className="mt-2">
            While you are not required to provide all personal data identified
            in this Privacy Policy to use our service, certain aspects of our
            services may not be available if you choose not to provide us with
            specific pieces of information. For instance, we may not be able to
            respond to your queries or provide you with the marketing material
            you have requested if we do not have your contact information.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            23. Use of Service Providers
          </h2>
          <p className="mt-2">
            We engage third-party companies and individuals ("Service
            Providers") to facilitate the operation of our Service, to provide
            the Service on our behalf, and to assist in the analysis of how our
            Service is used. These third parties have access to your Personal
            Data only to perform these tasks on our behalf and are obligated not
            to disclose or use it for any other purpose. They are also required
            to adhere to the same security and data protection standards as we
            do, ensuring your information remains safe and confidential.
          </p>
          <h2 className="mt-5 text-lg font-bold">24. Social Media Features</h2>
          <p className="mt-2">
            Our Service includes certain social media features, such as the
            Facebook "Like" button and widgets, such as the "Share" button or
            interactive mini-programs that run on our site. These features may
            collect your IP address, which page you are visiting on our site,
            and may set a cookie to enable the feature to function properly.
            Social media features and widgets are either hosted by a third party
            or hosted directly on our site. Your interactions with these
            features are governed by the privacy policy of the company providing
            it.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            25. Marketing Communications
          </h2>
          <p className="mt-2">
            You have the option to opt-in to receive marketing communications
            from us. If you decide to opt-in, we will send you information about
            promotions, new services, and other news about products we think
            might interest you. You can opt-out of receiving these
            communications at any time by following the unsubscribe link or
            instructions provided in any email we send, or by contacting us
            directly.
          </p>
          <h2 className="mt-5 text-lg font-bold">26. Contacting Us</h2>
          <p className="mt-2">
            If you have any questions about this Privacy Policy, your rights
            under this policy, or any other issues regarding the protection of
            your personal data, we encourage you to contact us. Please send your
            correspondence to Butkus@linkpricer.com or our postal address:
            Krivių g. 5, LT-01204 Vilnius, Lithuania. We commit to addressing
            your concerns and aim to resolve any issues in a timely and
            effective manner.
          </p>
        </div>
      </div>
    </Card>
  );
};

export default PrivacyPolicyPage;
