export const columnsDataReferrals = [
  {
    Header: 'FULL NAME',
    accessor: 'fullName',
  },
  {
    Header: 'TRANSACTIONS',
    accessor: 'totalTransactions',
  },
  {
    Header: 'TOTAL TRANSACTIONS VALUE',
    accessor: 'totalAmountPaid',
  },
  {
    Header: 'YOUR PROFIT',
    accessor: 'totalProfit',
  },
];
