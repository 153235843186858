import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './index.css';
import 'react-toastify/dist/ReactToastify.css';
import { CartProvider } from 'contexts/CartContext';
import { ProjectProvider } from 'contexts/ProjectContext';
import ScrollToTop from 'utils/scrollTop';
import App from './App';
import { CreditsProvider } from 'contexts/CreditsContext';
import { ToastContainer } from 'react-toastify';

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
  <BrowserRouter>
    <ScrollToTop />
    <ProjectProvider>
      <CartProvider>
        <CreditsProvider>
          <ToastContainer
            position="top-center"
            autoClose={2500}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <App />
        </CreditsProvider>
      </CartProvider>
    </ProjectProvider>
  </BrowserRouter>,
);
