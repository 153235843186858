import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import { MdClose } from 'react-icons/md';
import { post } from 'api';
import { getUserId } from 'api';
import defaultGif from 'assets/img/dashboards/defaultGif.gif';

const NewcomerModal = ({ isModalOpen, setIsModalOpen }) => {
  const { onClose } = useDisclosure();
  const userId = getUserId();

  const handleUpdateBacklink = async () => {
    const body = {
      userId: userId,
    };
    await post(`/api/misc/newcomer-popup`, body).then((res) => {
      onClose();
      setIsModalOpen(false);
    });
  };

  return (
    <Modal
      className="!z-[100]"
      isOpen={isModalOpen}
      onClose={onClose}
      disableEnforceFocus
      disableAutoFocus={true}
    >
      <ModalOverlay className="border-none bg-[#000] !opacity-40 outline-none" />
      <ModalContent className="top-[2vh] !m-auto max-w-[90%] outline-none md:!max-w-[40%]">
        <ModalBody className="max-h-[90vh] overflow-auto outline-none md:max-h-[96vh]">
          <Card extra={'w-full p-8 rounded-md outline-none'}>
            <div className="flex justify-end">
              <MdClose
                className="cursor-pointer"
                onClick={handleUpdateBacklink}
              />
            </div>
            <p className="text-center text-xl font-bold">
              Welcome to LinkPricer!
            </p>
            <p className="text-md mt-3">
              We're excited to have you on board. You've just taken the first
              step towards streamlining your backlink management process, and
              we’re here to help make it as smooth as possible.
            </p>
            <p className="text-md mt-2">
              Whenever you need assistance navigating the platform, simply look
              for the <strong>info button</strong> located next to the page
              title. It’s your go-to for tips and guidance on using all the
              features LinkPricer has to offer.
            </p>
            <p className="text-md mt-2">
              Feel free to explore, and remember—help is always just a click
              away!
            </p>
            <div className="mt-4 flex justify-center">
              <img src={defaultGif} alt="Newcomer Popup" />
            </div>
            <div className="mt-4 flex justify-center">
              <button
                onClick={handleUpdateBacklink}
                className="mt-4 rounded-md bg-brand-500 px-4 py-2 text-white"
              >
                Got it!
              </button>
            </div>
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default NewcomerModal;
