import React, { useState } from 'react';
import Scrapers from './components/Scrapers';
import {
  categoriesHeaders,
  usersHeaders,
  notificationsHeaders,
  articlesHeaders,
} from './variables/columnsData';
import Users from './components/Users';
import Notifications from './components/Notifications';
import Articles from './components/Articles';

const AdminPanel = () => {
  const [activeComponent, setActiveComponent] = useState('users');

  const handleButtonClick = (componentName) => {
    setActiveComponent(componentName);
  };

  return (
    <div>
      <div className="sm:flex sm:items-center sm:justify-between sm:space-x-3 md:flex md:space-x-5">
        <div className="mt-5 flex h-[300px] w-full flex-col items-center bg-blue-500 p-1.5 md:h-[50px] md:w-[66%] md:flex-row md:rounded-full">
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'users'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('users')}
          >
            Users
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'notifications'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('notifications')}
          >
            Notifications
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'scrapers'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('scrapers')}
          >
            Scrapers
          </button>
          <button
            className={`linear flex h-full w-full cursor-pointer select-none items-center justify-center rounded-[20px] text-sm font-bold uppercase text-brand-500 transition duration-200 ${
              activeComponent === 'articles'
                ? 'bg-white text-brand-500'
                : 'bg-transparent text-white'
            } `}
            onClick={() => handleButtonClick('articles')}
          >
            Articles
          </button>
        </div>
      </div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        {activeComponent === 'users' && <Users columnsData={usersHeaders} />}
        {activeComponent === 'notifications' && (
          <Notifications columnsData={notificationsHeaders} />
        )}
        {activeComponent === 'scrapers' && (
          <Scrapers columnsData={categoriesHeaders} />
        )}
        {activeComponent === 'articles' && (
          <Articles columnsData={articlesHeaders} />
        )}
      </div>
    </div>
  );
};

export default AdminPanel;
