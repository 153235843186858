import Card from 'components/card';
import { put, getUserId } from 'api';

const DeleteProfile = () => {
  const handleRemove = async () => {
    const userId = getUserId();
    if (window.confirm('Are you sure you want to delete this account?')) {
      const res = await put(`/api/user/deactivate/${userId}`);
      if (res.status === 200) {
        localStorage.removeItem('expiresAt');
        localStorage.removeItem('idToken');
        localStorage.removeItem('userId');
        localStorage.removeItem('token');
        localStorage.removeItem('accessToken');
        localStorage.removeItem('userRole');
        localStorage.removeItem('cartItems');
        localStorage.removeItem('userData');
        window.location.href = '/auth/login';
      } else {
        const data = await res.json();
        alert(data.message);
      }
    } else {
      console.log('Not deleted');
    }
  };

  return (
    <Card extra="flex-col md:flex-row py-6 px-[30px] justify-between md:items-center w-full">
      <div>
        <h3 className="mb-px text-xl font-bold text-navy-700 dark:text-white">
          Delete this account
        </h3>
        <p className="text-base text-gray-600">
          Here you can permanently delete this account
        </p>
      </div>
      <button
        onClick={() => handleRemove()}
        className="mt-4 flex cursor-pointer items-center justify-center rounded-xl border-2 border-red-500 px-4 py-2 text-base font-medium text-red-500 transition duration-200 hover:bg-red-50 active:bg-red-100 dark:active:bg-red-50 dark:active:opacity-80 md:mt-0"
      >
        Delete account
      </button>
    </Card>
  );
};

export default DeleteProfile;
