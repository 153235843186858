import React, { useEffect, useState } from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import { useLocation, useNavigate } from 'react-router-dom';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';
import { FcGoogle } from 'react-icons/fc';
import Checkbox from 'components/checkbox';
import { post, get } from 'api';

function SignUpCenter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const referralCode = params.get('referral');
  const [termsChecked, setTermsChecked] = useState(false);
  const [name, setName] = useState('');
  const [lastname, setLastname] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatpassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);
  const preLoginLocation = JSON.parse(
    sessionStorage.getItem('preLoginLocation'),
  );

  useEffect(() => {
    if (referralCode) {
      localStorage.setItem('referralCode', referralCode);
    }
  }, [referralCode]);

  const handleLogin = () => {
    window.location.href = `${process.env.REACT_APP_BACKEND_URL}/login/google`;
  };

  const handleInfoChange = (e) => {
    const { id, value } = e.target;
    if (id === 'firstname') {
      setName(value);
    } else if (id === 'lastname') {
      setLastname(value);
    } else if (id === 'email') {
      setEmail(value);
    } else if (id === 'password') {
      setPassword(value);
    } else if (id === 'repeatPassword') {
      setRepeatpassword(value);
    }
  };

  const validatePasswords = () => {
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters');
      return false;
    } else {
      setPasswordError('');
    }

    if (repeatPassword.length < 8) {
      setRepeatPasswordError('Password must be at least 8 characters');
      return false;
    } else if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords do not match');
      return false;
    } else {
      setRepeatPasswordError('');
    }

    return true;
  };

  const validateInputs = () => {
    const emailRegex = /\S+@\S+\.\S+/;
    if (!emailRegex.test(email)) {
      toastError('Please enter a valid email address');
      return false;
    }

    if (name.length < 2) {
      toastError('First name must be at least 2 characters');
      return false;
    }

    if (lastname.length < 2) {
      toastError('Last name must be at least 2 characters');
      return false;
    }

    return true;
  };

  const handleSignUp = async () => {
    setIsSubmitting(true);
    const utmParams = JSON.parse(localStorage.getItem('utm_params')) || {};

    if (!validateInputs()) {
      setIsSubmitting(false);
      return;
    }

    if (!validatePasswords()) {
      setIsSubmitting(false);
      toastError('Please check the password fields');
      return;
    }

    if (!termsChecked) {
      setIsSubmitting(false);
      toastError('Please accept the terms and conditions');
      return;
    }

    try {
      const body = {
        firstName: name,
        lastName: lastname,
        email,
        password,
        ...utmParams,
      };
      const res = await post('/login/sign-up', body);
      if (res.status === 200) {
        const refCode = localStorage.getItem('referralCode');
        const reffCode = refCode ? refCode : referralCode;
        const user = await res.json();
        if (reffCode) {
          await get(`/api/referrals/user/${reffCode}`)
            .then((res) => res.json())
            .then((data) => {
              const body = {
                referrerId: data.id,
                referredId: user.id,
              };
              post(`/api/referrals`, body).then((res) => {
                if (res.status === 200) {
                  localStorage.removeItem('referralCode');
                }
              });
            });
        }
        toastSuccess(
          'Account created successfully, redirecting to login page..',
        );
        setTimeout(() => {
          setIsSubmitting(false);
          if (preLoginLocation) {
            navigate(preLoginLocation);
          } else {
            navigate('/auth/login');
          }
        }, 2000);
      } else {
        const data = await res.json();
        toastError(data.message);
        setIsSubmitting(false);
      }
      setIsSubmitting(false);
    } catch (error) {
      console.error('Error submitting form:', error);
      setIsSubmitting(false);
    }
  };

  return (
    <Centered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[510px] h-max mx-2.5 md:mx-auto mt-12 mb-auto py-2.5 px-4 md:!p-[50px] pt-8 md:pt-[50px]">
          <h3 className="text-4xl font-bold text-navy-700 dark:text-white">
            Sign Up
          </h3>
          <p className="mb-5 ml-1 mt-[10px] text-base text-gray-600">
            Enter your email and password to sign up!
          </p>
          <div
            onClick={handleLogin}
            className="mt-4 flex h-[50px] w-full items-center justify-center gap-2 rounded-xl bg-lightPrimary transition duration-200 hover:cursor-pointer hover:bg-brand-500 hover:text-white dark:bg-navy-700 dark:text-white dark:hover:bg-navy-600"
          >
            <div className="rounded-full text-xl">
              <FcGoogle />
            </div>
            <p className="text-sm font-medium dark:text-white">
              Sign In with Google
            </p>
          </div>
          <div className="mb-4 mt-6 flex items-center gap-3">
            <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
            <p className="text-base font-medium text-gray-600"> or </p>
            <div className="h-px w-full bg-gray-200 dark:!bg-navy-700" />
          </div>
          <div className="mb-3 flex w-full items-center justify-center gap-4">
            <div className="w-1/2">
              <InputField
                variant="auth"
                extra="mb-3"
                label="First Name*"
                placeholder="John"
                id="firstname"
                type="text"
                onChange={handleInfoChange}
                value={name}
              />
            </div>
            <div className="w-1/2">
              <InputField
                variant="auth"
                extra="mb-3"
                label="Last Name*"
                placeholder="Doe"
                id="lastname"
                type="text"
                onChange={handleInfoChange}
                value={lastname}
              />
            </div>
          </div>
          <InputField
            variant="auth"
            extra="mb-3"
            label="Email*"
            placeholder="mail@mail.com"
            id="email"
            type="email"
            onChange={handleInfoChange}
            value={email}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label={passwordError ? passwordError : 'Password*'}
            placeholder="Password"
            id="password"
            type="password"
            onChange={handleInfoChange}
            state={passwordError ? 'error' : ''}
            value={password}
          />
          <InputField
            variant="auth"
            extra="mb-3"
            label={
              repeatPasswordError ? repeatPasswordError : 'Repeat password*'
            }
            placeholder="Password"
            id="repeatPassword"
            type="password"
            onChange={handleInfoChange}
            state={repeatPasswordError ? 'error' : ''}
            value={repeatPassword}
          />
          {/* Checkbox */}
          <div className="mt-2 flex items-center justify-between px-2">
            <div className="flex">
              <Checkbox
                id="terms"
                name="terms"
                value={termsChecked}
                onClick={() => setTermsChecked(!termsChecked)}
              />
              <p className="ml-2 text-sm font-medium text-navy-700 dark:text-white">
                By creating an account means you agree to the{' '}
                <a
                  href="https://app.linkpricer.com/terms-of-service"
                  target="blank"
                  rel="noreferrer"
                  className="border-b border-brand-500 text-brand-500 hover:border-brand-500 hover:text-brand-500 dark:text-white dark:hover:border-white"
                >
                  Terms of Service,
                </a>{' '}
                and our{' '}
                <a
                  href="https://app.linkpricer.com/privacy-policy"
                  target="blank"
                  rel="noreferrer"
                  className="border-b border-brand-500 text-brand-500 hover:border-brand-500 hover:text-brand-500 dark:text-white dark:hover:border-white"
                >
                  Privacy Policy,
                </a>{' '}
                and to receive newsletters.
              </p>
            </div>
          </div>

          <button
            className=" mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={() => handleSignUp()}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                <span>Creating account..</span>
              </div>
            ) : (
              <span>Create my account</span>
            )}
          </button>

          <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Already a member?
            </span>
            <a
              href="/auth/login"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Sign In
            </a>
          </div>
        </Card>
      }
    />
  );
}

export default SignUpCenter;
