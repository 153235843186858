import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export const toastSuccess = (message, closeTime) => {
  toast.success(message, {
    position: 'top-center',
    autoClose: closeTime ? closeTime : 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'success1',
  });
};

export const toastInfo = (message, closeTime) => {
  toast.info(message, {
    position: 'top-center',
    autoClose: closeTime ? closeTime : 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'info1',
  });
};

export const toastError = (message, close) => {
  toast.error(message, {
    position: 'top-center',
    autoClose: close ? close : 2000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: 'error1',
  });
};
