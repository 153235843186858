import React from 'react';
import WishlistTable from './components/WishlistTable';
import { backlinksHeaders } from './variables/columnsData';

const BacklinksWishlist = () => {
  return (
    <div>
      <div className="mt-5 grid h-full grid-cols-1 gap-5 md:grid-cols-1">
        <WishlistTable columnsData={backlinksHeaders} />
      </div>
    </div>
  );
};

export default BacklinksWishlist;
