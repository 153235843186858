import {
  MdChat,
  MdDashboard,
  MdHome,
  MdLock,
  MdPerson,
  MdFeaturedPlayList,
} from 'react-icons/md';

// Admin Imports
import ProjectOverview from 'views/admin/dashboards/projects/overview';
import NewProject from 'views/admin/dashboards/projects/newProject';
import EditProject from 'views/admin/dashboards/projects/editProject';
import AddCompetitor from 'views/admin/dashboards/projects/competitors';
import BacklinksSearch from 'views/admin/dashboards/backlinksSearch';
import BacklinksBulkSearch from 'views/admin/dashboards/backlinksBulkSearch';
import BacklinksWishlist from 'views/admin/dashboards/wishlist';
import AdminBacklinks from 'views/admin/dashboards/admin';

// Account Imports
import AccountBilling from 'views/admin/account/billing';
import AccountCredits from 'views/admin/account/credits';
import AccountSettings from 'views/admin/account/settings';
import AccountNotifications from 'views/admin/account/notifications';
import AccountInvoice from 'views/admin/account/invoice';
import AdminPanel from 'views/admin/account/admin';
import AccountReferrals from 'views/admin/account/referrals';

// Main Imports
import AccountBillingMain from 'views/admin/main/account/billing';
import AccountApplicationsMain from 'views/admin/main/account/application';
import AccountInvoiceMain from 'views/admin/main/account/invoice';
import AccountSettingsMain from 'views/admin/main/account/settings';
import AccountAllCourses from 'views/admin/main/account/courses';
import AccountCoursePage from 'views/admin/main/account/coursePage';

import UserNew from 'views/admin/main/users/newUser';
import UsersOverview from 'views/admin/main/users/overview';
import UsersReports from 'views/admin/main/users/reports';

import ProfileSettings from 'views/admin/main/profile/settings';
import ProfileOverview from 'views/admin/main/profile/overview';
import ProfileNewsfeed from 'views/admin/main/profile/newsfeed';

import ApplicationsKanban from 'views/admin/main/applications/kanban';
import ApplicationsDataTables from 'views/admin/main/applications/dataTables';
import ApplicationsCalendar from 'views/admin/main/applications/Calendar';

import EcommerceNewProduct from 'views/admin/main/ecommerce/newProduct';
import EcommerceProductSettings from 'views/admin/main/ecommerce/settingsProduct';
import EcommerceProductPage from 'views/admin/main/ecommerce/productPage';
import EcommerceOrderList from 'views/admin/main/ecommerce/orderList';
import EcommerceOrderDetails from 'views/admin/main/ecommerce/orderDetails';

// Landing Imports
import BacklinksList from 'views/landing/backlinks';
import SubscribePage from 'views/landing/subscribe';

// Others
import OthersNotifications from 'views/admin/main/others/notifications';
import OthersPricing from 'views/admin/main/others/pricing';
import OthersError from 'views/admin/main/others/404';
import Buttons from 'views/admin/main/others/buttons';

// Auth Imports
import ForgotPassword from 'views/auth/forgotPassword/ForgotPassword.jsx';
import LockCentered from 'views/auth/lock/LockCenter.jsx';
import SignIn from 'views/auth/signIn/SignIn.jsx';
import SignUp from 'views/auth/signUp/SignUp.jsx';
import ResetPassword from 'views/auth/resetPassword/ResetPassword.jsx';
import GoogleVerification from 'views/auth/google';

// Messages
import Messages from 'views/admin/messages';
import SuggestFeature from 'views/admin/feature/suggestFeature';
import PrivacyPolicyPage from 'views/landing/privacy-policy';
import TermsOfServicePage from 'views/landing/terms-of-service';

const routes = [
  {
    name: 'Dashboards',
    path: '/dashboards',
    icon: <MdHome className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Backlinks Search',
        layout: '/admin',
        path: '/dashboards/backlinks-search',
        component: <BacklinksSearch />,
      },
      {
        name: 'Backlinks Bulk Search',
        layout: '/admin',
        path: '/dashboards/backlinks-bulk-search',
        component: <BacklinksBulkSearch />,
      },
      {
        name: 'Backlinks Wishlist',
        layout: '/admin',
        path: '/dashboards/backlinks-wishlist',
        component: <BacklinksWishlist />,
      },
      // {
      //   name: 'Projects',
      //   path: '/dashboards/projects',
      //   collapse: true,
      //   items: [
      //     {
      //       name: 'Overview',
      //       layout: '/admin',
      //       path: '/dashboards/projects/overview',
      //       exact: false,
      //       component: <ProjectOverview />,
      //     },
      //     {
      //       name: 'New Project',
      //       layout: '/admin',
      //       path: '/dashboards/projects/newProject',
      //       exact: false,
      //       component: <NewProject />,
      //     },
      //     {
      //       name: 'Edit Project',
      //       layout: '/admin',
      //       path: '/dashboards/projects/editProject',
      //       exact: false,
      //       component: <EditProject />,
      //     },
      //     {
      //       name: 'Add Competitors',
      //       layout: '/admin',
      //       path: '/dashboards/projects/competitors',
      //       exact: false,
      //       component: <AddCompetitor />,
      //     },
      //   ],
      // },
      // {
      //   name: 'Admin Links',
      //   layout: '/admin',
      //   path: '/dashboards/admin',
      //   component: <AdminBacklinks />,
      //   adminView: true,
      // },
    ],
  },
  {
    name: 'Account',
    path: '/account',
    icon: <MdPerson className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      {
        name: 'Profile Settings',
        layout: '/admin',
        path: '/account/settings',
        exact: false,
        component: <AccountSettings />,
      },
      // {
      //   name: 'Billing Information',
      //   layout: '/admin',
      //   path: '/account/billing',
      //   exact: false,
      //   component: <AccountBilling />,
      // },
      {
        name: 'Credits',
        layout: '/admin',
        path: '/account/credits',
        exact: false,
        component: <AccountCredits />,
      },
      // {
      //   name: 'Invoices',
      //   layout: '/admin',
      //   path: '/account/invoice',
      //   exact: false,
      //   component: <AccountInvoice />,
      // },
      {
        name: 'Referrals',
        layout: '/admin',
        path: '/account/referrals',
        exact: false,
        component: <AccountReferrals />,
      },
      {
        name: 'Email Notifications',
        layout: '/admin',
        path: '/account/notifications',
        exact: false,
        component: <AccountNotifications />,
      },
      {
        name: 'Admin',
        layout: '/admin',
        path: '/account/admin',
        exact: false,
        component: <AdminPanel />,
        adminView: true,
      },
    ],
  },
  // --- Main pages ---
  // {
  //   name: 'Main Pages',
  //   path: '/main',
  //   icon: <MdDashboard className="text-inherit h-5 w-5" />,
  //   collapse: true,
  //   items: [
  //     {
  //       name: 'Account',
  //       path: '/main/account',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Billing',
  //           layout: '/admin',
  //           path: '/main/account/billing',
  //           exact: false,
  //           component: <AccountBillingMain />,
  //         },
  //         {
  //           name: 'Application',
  //           layout: '/admin',
  //           path: '/main/account/application',
  //           exact: false,
  //           component: <AccountApplicationsMain />,
  //         },
  //         {
  //           name: 'Invoice',
  //           layout: '/admin',
  //           path: '/main/account/invoice',
  //           exact: false,
  //           component: <AccountInvoiceMain />,
  //         },
  //         {
  //           name: 'Settings',
  //           layout: '/admin',
  //           path: '/main/account/settings',
  //           exact: false,
  //           component: <AccountSettingsMain />,
  //         },
  //         {
  //           name: 'All Courses',
  //           layout: '/admin',
  //           path: '/main/account/all-courses',
  //           exact: false,
  //           component: <AccountAllCourses />,
  //         },
  //         {
  //           name: 'Course Page',
  //           layout: '/admin',
  //           path: '/main/account/course-page',
  //           exact: false,
  //           component: <AccountCoursePage />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Ecommerce',
  //       path: '/ecommerce',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'New Product',
  //           layout: '/admin',
  //           path: '/main/ecommerce/new-prodcut',
  //           exact: false,
  //           component: <EcommerceNewProduct />,
  //         },
  //         {
  //           name: 'Product Settings',
  //           layout: '/admin',
  //           path: '/main/ecommerce/settings',
  //           exact: false,
  //           component: <EcommerceProductSettings />,
  //         },
  //         {
  //           name: 'Product Page',
  //           layout: '/admin',
  //           path: '/main/ecommerce/page-example',
  //           exact: false,
  //           component: <EcommerceProductPage />,
  //         },
  //         // Sitas tinkamas landingui
  //         {
  //           name: 'Order List',
  //           layout: '/admin',
  //           path: '/main/ecommerce/order-list',
  //           exact: false,
  //           component: <EcommerceOrderList />,
  //         },
  //         {
  //           name: 'Backlinks List',
  //           layout: '/landing',
  //           path: '/',
  //           exact: false,
  //           component: <BacklinksList />,
  //         },
  //         {
  //           name: 'Order Details',
  //           layout: '/admin',
  //           path: '/main/ecommerce/order-details',
  //           exact: false,
  //           component: <EcommerceOrderDetails />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Users',
  //       path: '/main/users',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'New User',
  //           layout: '/admin',
  //           path: '/main/users/new-user',
  //           exact: false,
  //           component: <UserNew />,
  //         },
  //         {
  //           name: 'Users Overview',
  //           layout: '/admin',
  //           path: '/main/users/users-overview',
  //           exact: false,
  //           component: <UsersOverview />,
  //         },
  //         {
  //           name: 'Users Reports',
  //           layout: '/admin',
  //           path: '/main/users/users-reports',
  //           exact: false,
  //           component: <UsersReports />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Applications',
  //       path: '/main/applications',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Kanban',
  //           layout: '/admin',
  //           path: '/main/applications/kanban',
  //           exact: false,
  //           component: <ApplicationsKanban />,
  //         },
  //         {
  //           name: 'Data Tables',
  //           layout: '/admin',
  //           path: '/main/applications/data-tables',
  //           exact: false,
  //           component: <ApplicationsDataTables />,
  //         },
  //         {
  //           name: 'Calendar',
  //           layout: '/admin',
  //           path: '/main/applications/calendar',
  //           exact: false,
  //           component: <ApplicationsCalendar />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Profile',
  //       path: '/main/profile',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Profile Overview',
  //           layout: '/admin',
  //           path: '/main/profile/overview',
  //           exact: false,
  //           component: <ProfileOverview />,
  //         },
  //         {
  //           name: 'Profile Settings',
  //           layout: '/admin',
  //           path: '/main/profile/settings',
  //           exact: false,
  //           component: <ProfileSettings />,
  //         },
  //         {
  //           name: 'News Feed',
  //           layout: '/admin',
  //           path: '/main/profile/newsfeed',
  //           exact: false,
  //           component: <ProfileNewsfeed />,
  //         },
  //       ],
  //     },
  //     {
  //       name: 'Others',
  //       path: '/main/others',
  //       collapse: true,
  //       items: [
  //         {
  //           name: 'Notifications',
  //           layout: '/admin',
  //           path: '/main/others/notifications',
  //           exact: false,
  //           component: <OthersNotifications />,
  //         },
  //         {
  //           name: 'Pricing',
  //           layout: '/auth',
  //           path: '/main/others/pricing',
  //           exact: false,
  //           component: <OthersPricing />,
  //         },
  //         {
  //           name: '404',
  //           layout: '/admin',
  //           path: '/main/others/404',
  //           exact: false,
  //           component: <OthersError />,
  //         },
  //         {
  //           name: 'Buttons',
  //           layout: '/admin',
  //           path: '/main/others/buttons',
  //           exact: false,
  //           component: <Buttons />,
  //         },
  //         {
  //           name: 'Messages',
  //           layout: '/admin',
  //           path: '/main/others/messages',
  //           exact: false,
  //           component: <Messages />,
  //         },
  //       ],
  //     },
  //   ],
  // },
  // {
  //   name: 'Live Chat',
  //   layout: '/admin',
  //   path: '/live-chat',
  //   icon: <MdChat className="text-inherit h-5 w-5" />,
  //   component: <Messages />,
  //   secondary: true,
  //   adminView: false,
  //   collapse: false,
  // },
  {
    name: 'Suggest Feature',
    layout: '/admin',
    path: '/suggest-feature',
    icon: <MdFeaturedPlayList className="text-inherit h-5 w-5" />,
    component: <SuggestFeature />,
    secondary: true,
    adminView: false,
    collapse: false,
  },
  // --- Landing ---
  {
    name: 'Backlinks List',
    layout: '/landing',
    path: '/',
    exact: false,
    component: <BacklinksList />,
    hide: true,
  },
  {
    name: 'Subscribe',
    layout: '/landing',
    path: '/subscribe',
    exact: false,
    component: <SubscribePage />,
    hide: true,
  },
  {
    name: 'Privacy Policy',
    layout: '/landing',
    path: '/privacy-policy',
    exact: false,
    component: <PrivacyPolicyPage />,
    hide: true,
  },
  {
    name: 'Terms of Service',
    layout: '/landing',
    path: '/terms-of-service',
    exact: false,
    component: <TermsOfServicePage />,
    hide: true,
  },
  // --- Authentication ---
  {
    name: 'Authentication',
    hide: true,
    path: '/auth',
    icon: <MdLock className="text-inherit h-5 w-5" />,
    collapse: true,
    items: [
      // --- Sign In ---
      {
        name: 'Log In',
        layout: '/auth',
        path: '/login/:referral?',
        component: <SignIn />,
      },
      {
        name: 'Google Log In',
        layout: '/auth',
        path: '/google',
        component: <GoogleVerification />,
      },
      {
        name: 'Sign Up',
        layout: '/auth',
        path: '/sign-up',
        component: <SignUp />,
      },
      {
        name: 'Reset Password',
        layout: '/auth',
        path: '/reset-password',
        component: <ResetPassword />,
      },
      {
        name: 'Forgot Password',
        layout: '/auth',
        path: '/forgot-password',
        component: <ForgotPassword />,
      },
      // {
      //   name: 'Centered',
      //   layout: '/admin',
      //   path: '/',
      //   component: <LockCentered />,
      // },
    ],
  },
];
export default routes;
