import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import Centered from 'layouts/auth/types/Centered';
import { post } from 'api';

function ForgotPasswordCenter() {
  const navigate = useNavigate();
  const [email, setEmail] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInfoChange = (e) => {
    const { id, value } = e.target;
    if (id === 'email') {
      setEmail(value);
    }
  };

  const handleSubmit = async () => {
    setIsSubmitting(true);
    const body = {
      email: email,
    };
    try {
      const res = await post('/passwords/forgot-password', body);
      if (res.status === 200) {
        setTimeout(() => {
          setIsSubmitting(false);
          navigate('/auth/login');
        }, 60000);
        toastSuccess('Password reset link sent successfully');
      } else {
        const data = await res.json();
        toastError(data.message);
        setIsSubmitting(false);
      }
    } catch (err) {
      console.log(err);
      toastError('Something went wrong');
      setIsSubmitting(false);
    }
  };

  return (
    <Centered
      maincard={
        <Card
          extra={
            'max-w-[405px] md:max-w-[550px] h-max mt-32 md:mt-[120px] mx-2.5 md:mx-auto mb-auto pb-2.5 pt-8 md:pt-2.5 px-4 md:!p-[50px]'
          }
        >
          <p className="mb-2 text-4xl font-bold text-navy-700 dark:text-white">
            Forgot Your Password?
          </p>
          <p className="mb-8 ml-1 mt-[10px] text-base text-gray-600">
            No problem. Just let us know your email address and we'll email you
            a password reset link that will allow you to choose a new one.
          </p>
          <InputField
            variant="auth"
            label="Email"
            placeholder="mail@mail.com"
            id="email"
            type="text"
            value={email}
            onChange={handleInfoChange}
          />
          <button
            className="mt-4 w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
            onClick={() => handleSubmit()}
            disabled={isSubmitting}
          >
            {isSubmitting ? (
              <div className="flex items-center justify-center">
                <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                <span>Emailing..</span>
              </div>
            ) : (
              <span>Email password reset link</span>
            )}
          </button>
        </Card>
      }
    />
  );
}

export default ForgotPasswordCenter;
