import React, { useMemo, useState, useEffect } from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight, MdDeleteForever } from 'react-icons/md';
import { remove, get, post } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import InputField from './InputField';

const Notifications = (props) => {
  const { columnsData } = props;
  const [notifications, setNotifications] = useState([]);
  const [newTitle, setNewTitle] = useState('');
  const [newMessage, setNewMessage] = useState('');
  const [submitting, setSubmitting] = useState(false);

  const getNotifications = async () => {
    await get('/api/misc/notifications/all').then((res) =>
      res.json().then((data) => {
        setNotifications(data);
      }),
    );
  };

  useEffect(() => {
    getNotifications();
  }, []);

  const handleNewNotificationAdd = async () => {
    if (newTitle === '') {
      toastError('Please enter a notification title');
    } else if (newMessage === '') {
      toastError('Please enter a notification message');
    } else {
      setSubmitting(true);
      const body = {
        type: 'generalMessage',
        title: newTitle,
        message: newMessage,
      };
      await post('/api/misc/notifications', body).then((res) => {
        if (res.status === 200) {
          toastSuccess('Notification successfully added');
          setNewTitle('');
          setNewMessage('');
          setSubmitting(false);
          getNotifications();
        } else {
          toastError('Something went wrong');
          setSubmitting(false);
        }
      });
      setSubmitting(false);
    }
  };

  const handleRemoveNotification = async (id) => {
    if (window.confirm('Are you sure you want to delete this notification?')) {
      await remove(`/api/misc/notifications/${id}`).then((res) => {
        if (res.status === 200) {
          toastSuccess('Notification deleted successfully');
          getNotifications();
        } else {
          toastError('Something went wrong');
        }
      });
    }
  };

  const handleDataChange = (e, type) => {
    if (type === 'title') {
      setNewTitle(e.target.value);
    } else {
      setNewMessage(e.target.value);
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => notifications, [notifications]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4 3xl:w-2/3'}>
      <div className="mb-4 flex flex-col items-center justify-start md:flex-row md:justify-between">
        <InputField
          extra="w-full md:w-1/2"
          id="title"
          type="text"
          placeholder="Notification Title"
          state="normal"
          disabled={false}
          onChange={(e) => handleDataChange(e, 'title')}
          value={newTitle}
        />

        <InputField
          extra="w-full mt-2 md:w-1/2 md:ml-2 md:mt-0"
          id="message"
          type="text"
          placeholder="Notification Message"
          state="normal"
          disabled={false}
          onChange={(e) => handleDataChange(e, 'message')}
          value={newMessage}
        />
        <button
          disabled={submitting}
          onClick={() => handleNewNotificationAdd()}
          className="linear ml-2 mt-2 flex w-full items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:mt-0 md:w-1/3 md:w-1/6"
        >
          {submitting ? (
            <div className="flex items-center justify-center">
              <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
              <span>Adding..</span>
            </div>
          ) : (
            <span>Add</span>
          )}
        </button>
      </div>
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/3 md:w-3/4"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-4 overflow-x-scroll md:overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse md:table-fixed"
          variant="simple"
          color="gray-500"
          mb="24px"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'TITLE') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'NOTIFICATION') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'DELETE') {
                      const itemId = cell.row.original.id;
                      data = (
                        <button
                          onClick={() => handleRemoveNotification(itemId)}
                          className="text-2xl font-bold text-navy-700 hover:text-red-500 dark:text-white dark:hover:text-red-500"
                        >
                          <MdDeleteForever />
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of{' '}
            {Math.ceil(notifications.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Notifications;
