import Card from 'components/card';

const TermsOfServicePage = () => {
  return (
    <Card extra={'mt-3 w-full md:w-1/2 h-full bg-white mx-auto'}>
      <div className="container mx-auto flex h-full min-h-[90vh] flex-col px-8 py-4">
        <h1 className="mt-5 text-center text-3xl font-bold">
          TERMS OF SERVICE (TOS)
        </h1>
        <p className="mt-3 text-center">
          Last updated: <span className="font-bold">15th July 2024</span>
        </p>
        <div className="mt-8 flex flex-col">
          <h2 className="text-lg font-bold">1. Introduction</h2>
          <p className="mt-2">
            Welcome to Linkpricer.com, brought to you by Linkpricer, UAB
            ("Linkpricer", "we", "us", "our"). We are registered in Lithuania
            under company code 306947938 and operate from our registered office
            at Krivių g. 5, LT-01204 Vilnius. As your premier marketplace for
            premium backlinks, Linkpricer.com is dedicated to "Building Links,
            Boosting Growth." We provide a robust platform where you can buy
            backlinks with confidence, knowing that we handle all the
            intricacies and ensure a seamless transaction process.
            <br />
            <br />
            By accessing or using our services, you agree to be bound by these
            Terms of Service, which govern your access to and use of
            Linkpricer.com, including any content, functionality, and services
            offered on or through our website. We urge you to read these Terms
            of Service carefully before you start to use the website. By using
            the website, or by clicking to accept or agree to the Terms of
            Service when this option is made available to you, you accept and
            agree to be bound and abide by these Terms of Service and our
            Privacy Policy, incorporated herein by reference.
          </p>
          <h2 className="mt-5 text-lg font-bold">2. Definitions</h2>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>"Service"</strong> refers to the functionalities and
              applications provided by Linkpricer.com accessible via our website
              or through third-party sites endorsed by us.
            </li>
            <li className="ml-5 mt-2">
              <strong>"User"</strong> denotes any individual or entity that
              accesses or uses our Service, whether registered or not.
            </li>
            <li className="ml-5 mt-2">
              <strong>"Content"</strong> encompasses all visual, textual, or
              aural data that is encountered as part of your experience using
              Linkpricer.com. This includes, but is not limited to, any user
              input, algorithmically generated data, and data scraped from
              third-party sources.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">
            3. User Accounts and Responsibilities
          </h2>
          <p className="mt-2">
            To access certain features of the Service, you must register an
            account. During registration, you agree to provide truthful,
            accurate, and complete information as prompted by the registration
            form, and to update this information to maintain its accuracy. You
            are solely responsible for maintaining the confidentiality of your
            account information, including your password, and for all activity
            that occurs under your account as a result of your failing to keep
            this information secure and confidential. You agree to notify
            Linkpricer.com immediately of any unauthorized use of your account
            or password, or any other breach of security.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            4. Data Collection and Usage
          </h2>
          <p className="mt-2">
            By using our Service, you authorize us to collect, store, and use
            this data in accordance with our Privacy Policy. The user bears
            responsibility for ensuring that any data they provide directly does
            not violate any third-party rights or applicable laws.
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Search Engine Guidelines:</strong> Users must comply with
              the latest search engine guidelines to ensure that the use of
              backlinks does not negatively impact search rankings. The Service
              periodically reviews backlinks to ensure compliance with these
              guidelines.
            </li>
            <li className="ml-5 mt-2">
              <strong>Prohibited Practices:</strong> The Service prohibits the
              use of practices such as link spamming or any manipulative tactics
              that could lead to penalization by search engines. Users engaging
              in such practices will face account suspension or termination.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">
            5. Intellectual Property Rights
          </h2>
          <p className="mt-2">
            The intellectual property rights in the content displayed on
            Linkpricer.com, including without limitation the website design,
            text, graphics, and the selection and arrangement thereof, are owned
            by Linkpricer.com or its licensors. Users may use the site content
            for their personal, non-commercial use, but no part of
            Linkpricer.com may be reproduced, duplicated, copied, sold, resold,
            visited, or otherwise exploited for any commercial purpose without
            the express written consent of Linkpricer.com. The unauthorized use
            or reproduction of any content from this site may be a violation of
            statutory or common law rights which could subject the infringer to
            legal action.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            6. Third-party Websites and Content
          </h2>
          <p className="mt-2">
            Our Service may include materials from third-parties and hyperlinks
            to other web sites or resources. Linkpricer.com does not control the
            content and resources that appear on these sites and is not
            responsible for the practices employed by websites linked to or from
            our Service. In addition, these sites or services, including their
            content and links, may be constantly changing. These sites and
            services may have their own privacy policies and customer service
            policies, or no policy at all. We encourage you to review the
            privacy policies of any third party sites or services before
            providing any of them with your personal information.
          </p>
          <h2 className="mt-5 text-lg font-bold">7. Prohibited Conduct</h2>
          <p className="mt-2">You are expressly prohibited from:</p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              Using the Service in any way that breaches any applicable local,
              national, or international law or regulation.
            </li>
            <li className="ml-5 mt-2">
              Accessing all or any part of the Service in a manner that may
              cause damage, disable, overburden, or impair any aspect of any of
              our systems or security or interfere with other users.
            </li>
            <li className="ml-5 mt-2">
              Using the Service to transmit, or procure the sending of, any
              unsolicited or unauthorized advertising or promotional material or
              any other form of similar solicitation (spam).
            </li>
            <li className="ml-5 mt-2">
              Using the Service to knowingly transmit any data, send or upload
              any material that contains viruses, Trojan horses, worms,
              time-bombs, keystroke loggers, spyware, adware, or any other
              harmful programs or similar computer code designed to adversely
              affect the operation of any computer software or hardware.
            </li>
          </ul>
          <h2 className="mt-5 text-lg font-bold">8. Content Takedown Policy</h2>
          <p className="mt-2">
            Linkpricer.com respects the intellectual property rights of others
            and expects users of our services to do the same. We have
            implemented a takedown policy to address complaints concerning
            content transmitted through our service that violates copyright or
            other intellectual property rights. If you believe that your work
            has been copied and posted on the service in a way that constitutes
            copyright infringement, please provide our copyright agent with the
            following information:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              A physical or electronic signature of the person authorized to act
              on behalf of the owner of the copyright interest;
            </li>
            <li className="ml-5 mt-2">
              A description of the copyrighted work that you claim has been
              infringed;
            </li>
            <li className="ml-5 mt-2">
              A description of where the infringing material is located on the
              service;
            </li>
            <li className="ml-5 mt-2">
              Your address, telephone number, and email address;
            </li>
            <li className="ml-5 mt-2">
              A written statement by you that you have a good faith belief that
              the disputed use is not authorized by the copyright owner, its
              agent, or the law;
            </li>
            <li className="ml-5 mt-2">
              A statement by you, made under penalty of perjury, that the above
              information in your notice is accurate and that you are the
              copyright owner or authorized to act on the copyright owner's
              behalf.
            </li>
          </ul>
          <p className="mt-2">
            Complaints should be sent to our designated copyright agent. We
            respond to all notices of alleged copyright infringement and
            terminate accounts of repeat infringers according to the process set
            out in this policy.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            9. Disclaimer of Warranties
          </h2>
          <p className="mt-2">
            You expressly understand and agree that your use of the
            Linkpricer.com service is at your sole risk. The service is provided
            on an "AS IS" and "AS AVAILABLE" basis. Linkpricer.com and its
            affiliates, subsidiaries, officers, directors, employees, agents,
            partners, and licensors expressly disclaim all warranties of any
            kind, whether express or implied, including, but not limited to, the
            implied warranties of merchantability, fitness for a particular
            purpose, and non-infringement. Furthermore, Linkpricer.com makes no
            warranty that (i) the service will meet your requirements; (ii) the
            service will be uninterrupted, timely, secure, or error-free; (iii)
            the results that may be obtained from the use of the service will be
            accurate or reliable; and (iv) any errors in the software will be
            corrected.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            10. Limitation of Liability
          </h2>
          <p className="mt-2">
            You expressly understand and agree that Linkpricer.com and its
            affiliates, subsidiaries, officers, directors, employees, agents,
            partners, and licensors shall not be liable to you for any direct,
            indirect, incidental, special, consequential, or exemplary damages,
            including, but not limited to, damages for loss of profits,
            goodwill, use, data, or other intangible losses (even if
            Linkpricer.com has been advised of the possibility of such damages),
            resulting from: (i) the use or the inability to use the service;
            (ii) the cost of procurement of substitute goods and services; (iii)
            unauthorized access to or alteration of your transmissions or data;
            (iv) statements or conduct of any third party on the service; or (v)
            any other matter relating to the service.
          </p>
          <h2 className="mt-5 text-lg font-bold">11. Indemnification</h2>
          <p className="mt-2">
            You agree to defend, indemnify, and hold harmless Linkpricer.com,
            its parent, subsidiaries, affiliates, officers, agents, employees,
            partners, and licensors from any claim, demand, loss, liability, or
            expense, including reasonable attorney’s fees, arising out of or
            related to your use of the Service, your violation of these Terms of
            Service, or your violation of any rights of another party. This
            obligation will survive the termination or expiration of these Terms
            and your use of the Service.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            12. Modification of Terms and Services
          </h2>
          <p className="mt-2">
            Linkpricer.com reserves the right at any time to modify or
            discontinue, temporarily or permanently, the Service (or any part
            thereof) with or without notice. You agree that Linkpricer.com shall
            not be liable to you or to any third party for any modification,
            suspension, or discontinuance of the Service. Furthermore, we may
            amend these Terms of Service at any time by posting the amended
            terms on the Linkpricer.com site. Your continued use of the Service
            after any such changes constitutes your acceptance of the new Terms
            of Service.
          </p>
          <h2 className="mt-5 text-lg font-bold">13. Termination</h2>
          <p className="mt-2">
            Linkpricer.com may terminate your access to all or any part of the
            Service at any time, with or without cause, with or without notice,
            effective immediately, which may result in the forfeiture and
            destruction of all information associated with your membership. If
            you wish to terminate your account, you may do so by following the
            instructions on the Site or through the Service interface. All
            provisions of the Terms of Service which by their nature should
            survive termination shall survive termination, including, without
            limitation, ownership provisions, warranty disclaimers, indemnity,
            and limitations of liability.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            14. Governing Law and Jurisdiction
          </h2>
          <p className="mt-2">
            These Terms of Service shall be governed by and construed in
            accordance with the laws of the Lithuania, without regard to its
            conflict of law provisions. You and Linkpricer.com agree to submit
            to the personal and exclusive jurisdiction of the courts located in
            Lithuania to resolve any dispute or claim arising from these Terms
            of Service.
          </p>
          <h2 className="mt-5 text-lg font-bold">15. Dispute Resolution</h2>
          <p className="mt-2">
            In the event of any dispute arising between you and Linkpricer.com
            related to these Terms of Service, we strongly encourage you to
            first contact us directly to seek a resolution by going to our
            customer support section on our website. If we cannot resolve the
            dispute through direct discussion, we may require that it be
            resolved through mediation or arbitration, administered by a neutral
            arbitration entity selected by Linkpricer.com, according to the
            rules and procedures of said entity applicable at the time the
            arbitration is initiated.
          </p>
          <h2 className="mt-5 text-lg font-bold">16. Fees and Payment</h2>
          <p className="mt-2">
            Linkpricer.com may charge fees for access to certain parts of the
            Service or for specific features. You agree to pay any such fees as
            agreed upon when you register for these parts of the Service. All
            fees are non-refundable unless otherwise explicitly stated. Fees may
            vary based on your location and other factors, and Linkpricer.com
            reserves the right to change any fees at any time at its sole
            discretion. All fees are exclusive of all taxes, levies, or duties
            imposed by taxing authorities, and you shall be responsible for
            payment of all such taxes, levies, or duties.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            17. Refund and Cancellation Policy
          </h2>
          <p className="mt-2">
            You may cancel your Linkpricer.com account at any time; however,
            there are no refunds for cancellation. In the event that
            Linkpricer.com suspends or terminates your account or this
            Agreement, you understand and agree that you shall receive no refund
            or exchange for any Linkpricer.com Property, any unused time on a
            subscription, any license or subscription fees, any content or data
            associated with your account, or for anything else.
          </p>
          <h2 className="mt-5 text-lg font-bold">18. Privacy Policy</h2>
          <p className="mt-2">
            Your privacy is important to us. Our Privacy Policy is incorporated
            into these Terms of Service by reference. It explains how we
            collect, use, and share information about you when you access our
            website or use our services. By agreeing to these Terms of Service,
            you also give your consent to the way we may handle your personal
            information as described in our Privacy Policy. Please be sure to
            read and understand our Privacy Policy before using our services.
          </p>
          <h2 className="mt-5 text-lg font-bold">
            19. User Feedback and Suggestions
          </h2>
          <p className="mt-2">
            Any feedback, comments, ideas, improvements, or suggestions
            (collectively, "Feedback") provided by you to Linkpricer.com with
            respect to the Service shall remain the sole and exclusive property
            of Linkpricer.com. Linkpricer.com shall be free to use, copy,
            modify, publish, or redistribute the Feedback for any purpose and in
            any way without any credit or any compensation to you.
          </p>
          <h2 className="mt-5 text-lg font-bold">20. Accessibility</h2>
          <p className="mt-2">
            Linkpricer.com is committed to ensuring accessibility of its Service
            to all users, including those with disabilities. We strive to
            maintain compliance with applicable standards and guidelines
            regarding accessibility and will continue to incorporate
            accessibility features on our site. If you have difficulty using or
            accessing any element of this website or the Linkpricer.com
            services, please feel free to contact us and we will work with you
            to provide the information, item, or transaction you seek through a
            communication method that is accessible for you, consistent with
            applicable law.
          </p>
          <h2 className="mt-5 text-lg font-bold">21. Contact Information</h2>
          <p className="mt-2">
            If you have any questions about these Terms of Service or the
            practices of this site, please contact us at:
          </p>
          <ul className="mt-2 list-inside list-disc">
            <li className="ml-5">
              <strong>Email:</strong> Butkus@linkpricer.com
            </li>
            <li className="ml-5 mt-2">
              <strong>Phone:</strong> +370 600 00000
            </li>
            <li className="ml-5 mt-2">
              <strong>Address:</strong> Krivių g. 5, LT-01204 Vilnius, Lithuania
            </li>
          </ul>
          <p className="mt-2">
            Linkpricer.com welcomes your questions and suggestions and is
            committed to providing a high standard of service to all users.
          </p>
          <h2 className="mt-5 text-lg font-bold">22. Amendments</h2>
          <p className="mt-2">
            Linkpricer.com reserves the right to amend these Terms of Service at
            any time and without notice, and it is your responsibility to review
            these Terms of Service for any changes. Your use of the
            Linkpricer.com website following any amendment of these Terms of
            Service will signify your assent to and acceptance of its revised
            terms.
          </p>
          <h2 className="mt-5 text-lg font-bold">23. Severability</h2>
          <p className="mt-2">
            If any provision of these Terms of Service is found by a court of
            competent jurisdiction to be invalid or unenforceable, the parties
            nevertheless agree that the court should endeavor to give effect to
            the parties' intentions as reflected in the provision, and the other
            provisions of these Terms of Service remain in full force and
            effect.
          </p>
          <h2 className="mt-5 text-lg font-bold">24. Waiver</h2>
          <p className="mt-2">
            No waiver of any term of these Terms of Service shall be deemed a
            further or continuing waiver of such term or any other term, and
            Linkpricer.com's failure to assert any right or provision under
            these Terms of Service shall not constitute a waiver of such right
            or provision.
          </p>
          <h2 className="mt-5 text-lg font-bold">25. Entire Agreement</h2>
          <p className="mt-2">
            These Terms of Service, together with any amendments and any
            additional agreements you may enter into with Linkpricer.com in
            connection with the Service, shall constitute the entire agreement
            between you and Linkpricer.com concerning the Service. The failure
            of Linkpricer.com to exercise or enforce any right or provision of
            these Terms of Service shall not constitute a waiver of such right
            or provision.
          </p>
        </div>
      </div>
    </Card>
  );
};

export default TermsOfServicePage;
