import React, { useEffect, useState } from 'react';
import { get } from 'api';
import moment from 'moment';

const Scrapers = () => {
  const [scrapersInfo, setScrapersInfo] = useState([]);

  const fetchScrapersInfo = async () => {
    try {
      const response = await get('/api/scrape/status');
      const data = await response.json();
      setScrapersInfo(data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchScrapersInfo();
  }, []);

  const handleScraperStart = async (scraper) => {
    try {
      const response = await get(`/api/scrape/start/${scraper}`);
      alert('Scraper started');
      console.log(response);
    } catch (error) {
      console.log(error);
    }
  };

  const handleScraperStop = async (scraper) => {
    try {
      const response = await get(`/api/scrape/stop/${scraper}`);
      const data = await response.text();
      alert(data);
      fetchScrapersInfo();
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className="h-full w-full rounded-[20px]">
      <div className="!z-5 shadow-shadow-300 relative mt-2 flex w-full flex-col rounded-[20px] bg-white bg-clip-border !p-5 shadow-2xl">
        <p className="mb-4 text-lg font-bold">Scrapers Summary</p>
        <table className="min-w-full bg-white">
          <thead>
            <tr>
              <th className="border-b px-4 py-2">Marketplace</th>
              <th className="border-b px-4 py-2">Start</th>
              <th className="border-b px-4 py-2">Stop</th>
              <th className="border-b px-4 py-2">Last Updated</th>
              <th className="border-b px-4 py-2">Last Updated Domains</th>
              <th className="border-b px-4 py-2">Total Domains</th>
            </tr>
          </thead>
          <tbody>
            {scrapersInfo.map((scraper) => {
              return (
                <tr key={scraper.marketplace}>
                  <td className="border-b px-4 py-2 text-center">
                    {scraper.marketplace}
                  </td>
                  <td className="border-b px-4 py-2 text-center">
                    {scraper.marketplace !== 'app.getfluence.com' &&
                      scraper.marketplace !== 'app.linkhouse.co' &&
                      scraper.marketplace !== 'app.nobsmarketplace.com' && (
                        <button
                          className="rounded bg-blue-500 px-4 py-2 font-bold text-white hover:bg-blue-600"
                          onClick={() =>
                            handleScraperStart(scraper.marketplace)
                          }
                        >
                          START
                        </button>
                      )}
                  </td>
                  <td className="border-b px-4 py-2 text-center">
                    {scraper.marketplace !== 'app.getfluence.com' &&
                      scraper.marketplace !== 'app.linkhouse.co' &&
                      scraper.marketplace !== 'app.nobsmarketplace.com' && (
                        <button
                          className="rounded bg-red-500 px-4 py-2 font-bold text-white hover:bg-red-600"
                          onClick={() => handleScraperStop(scraper.marketplace)}
                        >
                          STOP
                        </button>
                      )}
                  </td>
                  <td className="border-b px-4 py-2 text-center">
                    {scraper.lastUpdatedAt
                      ? moment(scraper.lastUpdatedAt).format(
                          'YYYY-MM-DD HH:mm:ss',
                        )
                      : 'N/A'}
                  </td>
                  <td className="border-b px-4 py-2 text-center">
                    {scraper.domainsCount.toLocaleString()}
                  </td>
                  <td className="border-b px-4 py-2 text-center">
                    {scraper.totalDomains.toLocaleString()}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Scrapers;
