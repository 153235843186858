import React, { useState } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { toastSuccess, toastError } from 'utils/toasts';
import Centered from 'layouts/auth/types/Centered';
import Card from 'components/card';
import InputField from 'components/fields/InputField';
import { post } from 'api';

function VerificationCenter() {
  const navigate = useNavigate();
  const location = useLocation();
  const params = new URLSearchParams(location.search);
  const passwordToken = params.get('passwordToken');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatpassword] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [repeatPasswordError, setRepeatPasswordError] = useState('');
  const [isSubmitting, setIsSubmitting] = useState(false);

  const handleInfoChange = (e) => {
    const { id, value } = e.target;
    if (id === 'password') {
      setPassword(value);
    }
    if (id === 'repeatPassword') {
      setRepeatpassword(value);
    }
  };

  const validatePasswords = () => {
    if (password.length < 8) {
      setPasswordError('Password must be at least 8 characters');
      return false;
    } else {
      setPasswordError('');
    }

    if (repeatPassword.length < 8) {
      setRepeatPasswordError('Password must be at least 8 characters');
      return false;
    } else if (password !== repeatPassword) {
      setRepeatPasswordError('Passwords do not match');
      return false;
    } else {
      setRepeatPasswordError('');
    }

    return true;
  };

  const handleSubmit = async () => {
    if (validatePasswords()) {
      setIsSubmitting(true);
      const body = {
        token: passwordToken,
        newPassword: password,
      };
      try {
        const res = await post('/passwords/reset-password', body);
        if (res.status === 200) {
          toastSuccess('Password changed successfully');
          setTimeout(() => {
            setIsSubmitting(false);
            setPassword('');
            setRepeatpassword('');
            navigate('/auth/login');
          }, 2000);
        } else {
          const data = await res.json();
          toastError(data.message);
          setIsSubmitting(false);
        }
      } catch (err) {
        toastError('Something went wrong');
        setIsSubmitting(false);
      }
    }
  };

  return (
    <Centered
      maincard={
        <Card extra="max-w-[405px] md:max-w-[550px] h-max mt-32 md:mt-[120px] mx-2.5 md:mx-auto mb-auto pb-2.5 pt-8 md:pt-2.5 px-4 md:!p-[50px]">
          <h3 className="mt-1 text-4xl font-bold text-navy-700 dark:text-white">
            Reset Your Password
          </h3>
          <p className="ml-1 mt-[15px] text-[15px] text-gray-600">
            Here you can set your new password
          </p>
          <div className="mt-7 grid grid-cols-1 gap-3">
            <InputField
              variant="auth"
              extra="mb-3"
              label={passwordError ? passwordError : 'Password*'}
              placeholder="Password"
              id="password"
              type="password"
              onChange={handleInfoChange}
              state={passwordError ? 'error' : ''}
              value={password}
            />
            <InputField
              variant="auth"
              extra="mb-3"
              label={
                repeatPasswordError ? repeatPasswordError : 'Repeat password*'
              }
              placeholder="Password"
              id="repeatPassword"
              type="password"
              onChange={handleInfoChange}
              state={repeatPasswordError ? 'error' : ''}
              value={repeatPassword}
            />
            <div className="mt-4 flex w-full justify-end">
              <button
                className="w-full rounded-xl bg-brand-500 py-3 text-base font-medium text-white transition duration-200 hover:bg-brand-600 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                onClick={() => handleSubmit()}
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <div className="flex items-center justify-center">
                    <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
                    <span>Changing Password..</span>
                  </div>
                ) : (
                  <span>Change Password</span>
                )}
              </button>
            </div>
          </div>
          <div className="mt-3">
            <span className="text-sm font-medium text-navy-700 dark:text-gray-500">
              Remembered your password?
            </span>
            <a
              href="/"
              className="ml-1 text-sm font-medium text-brand-500 hover:text-brand-500 dark:text-white"
            >
              Sign In
            </a>
          </div>
        </Card>
      }
    />
  );
}

export default VerificationCenter;
