export const categoryOptions = [
  'Finance',
  'News',
  'Shopping',
  'Travel',
  'Games',
  'Business',
  'Gambling',
  'Betting',
  'Food',
  'Health',
  'Adult',
  'Sports',
  'Animals',
  'General',
  'Home',
  'Politics',
  'Economy',
  'Art',
  'Computers',
  'Movies',
  'Fashion',
  'Books',
  'Education',
  'Law',
  'Beauty',
  'Technology',
  'Real estate',
  'Automotive',
  'Music',
  'Career',
  'Blog',
  'Gaming',
  'Dating',
  'Gardening',
  'Marketing',
  'Travel',
  'Legal',
  'Outdoors',
  'Weather',
  'Psychology',
  'Cosmetics',
  'Jewelry',
  'Family',
  'Insurance',
  'Parenting',
]
  .sort((a, b) => a.localeCompare(b))
  .map((category) => ({ value: category, label: category }));
