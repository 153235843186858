import React, { useState, useEffect, useMemo } from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { toastSuccess, toastError } from 'utils/toasts';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import { MdEdit } from 'react-icons/md';
import InputField from 'components/fields/InputField';
import { put } from 'api';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { get } from 'api';

const generateSlug = (text) => {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, '')
    .replace(/[\s_-]+/g, '-')
    .replace(/^-+|-+$/g, '');
};

const EditArticle = ({ itemId, getArticles }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const [editorState, setEditorState] = useState(EditorState.createEmpty());
  const [articleTitle, setArticleTitle] = useState('');
  const [articleSlug, setArticleSlug] = useState('');

  const wordCount = useMemo(() => {
    return editorState.getCurrentContent().getPlainText(' ').split(' ').length;
  }, [editorState]);

  useEffect(() => {
    const getArticleFromDb = async () => {
      const res = await get(`/api/misc/article/${itemId}`);
      const data = await res.json();

      const contentState = convertFromRaw(JSON.parse(data[0].content));
      setEditorState(EditorState.createWithContent(contentState));
      setArticleTitle(data[0].title);
      setArticleSlug(data[0].slug);
    };
    getArticleFromDb();
  }, [itemId]);

  const handleChange = (newEditorState) => {
    setEditorState(newEditorState);
  };

  const handleNewArticleTitle = (e) => {
    setArticleTitle(e.target.value);
  };

  const handleNewArticleSlug = (e) => {
    const value = e.target.value;
    setArticleSlug(value);
  };

  const handleSave = async () => {
    const contentState = editorState.getCurrentContent();
    const rawContent = convertToRaw(contentState);
    const rawContentString = JSON.stringify(rawContent);

    const body = {
      title: articleTitle,
      slug: articleSlug,
      content: rawContentString,
    };
    const res = await put(`/api/misc/article/${itemId}`, body);
    if (res.status === 200) {
      toastSuccess('Article updated successfully');
      getArticles();
      onClose();
    } else {
      toastError('Error updating article');
    }
  };

  return (
    <div>
      <button
        onClick={onOpen}
        className="text-xl font-bold text-navy-700 hover:text-brand-500 dark:text-white dark:hover:text-red-500"
        role="menuitem"
      >
        <MdEdit />
      </button>
      <Modal className="!z-[100]" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay className="bg-[#000] !opacity-30" />
        <ModalContent className="top-[10vh] !m-auto max-w-[90%] md:!max-w-[70%]">
          <ModalBody className="max-h-[90vh] overflow-auto">
            <Card extra={'w-full p-4 rounded-md'}>
              <div className="relative flex items-center justify-center border-b-2 border-gray-200 pb-2 font-[600] dark:border-gray-700">
                Write or Edit Article
              </div>
              <div className="mt-4 flex items-center justify-between gap-4">
                <div className="w-full">
                  <InputField
                    extra="w-full"
                    label="Article Title"
                    id="articleTitle"
                    type="text"
                    placeholder="Article title"
                    state="normal"
                    disabled={false}
                    onChange={(e) => handleNewArticleTitle(e)}
                    value={articleTitle || ''}
                  />
                </div>
                <div className="flex w-full items-center gap-2">
                  <InputField
                    extra="w-full"
                    label="Article Slug"
                    id="articleSlug"
                    type="text"
                    placeholder="Article slug"
                    state="normal"
                    disabled={false}
                    onChange={(e) => handleNewArticleSlug(e)}
                    value={articleSlug || ''}
                  />
                  <div className="flex h-[80px] items-end">
                    <button
                      onClick={() => setArticleSlug(generateSlug(articleTitle))}
                      className="flex h-[48px] items-center rounded-md bg-blue-500 px-4 py-2 text-sm text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                    >
                      Generate Slug
                    </button>
                  </div>
                </div>
              </div>
              <h2 className="mb-2 font-bold">Article Content</h2>
              <div className="editorJs flex items-center rounded-[12px] border-2 border-gray-100 p-4">
                <Editor
                  editorState={editorState}
                  onEditorStateChange={handleChange}
                />
              </div>
              <div className="mt-5 flex justify-between">
                <button
                  onClick={() => onClose()}
                  className="bg-transparent ml-0 rounded-md border border-gray-500 px-4 py-2 focus:outline-none focus:ring-2 focus:ring-gray-500 focus:ring-opacity-50"
                >
                  Cancel
                </button>
                <button
                  onClick={() => handleSave()}
                  className="rounded-md bg-blue-500 px-4 py-2 text-white hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50"
                >
                  Save
                </button>
              </div>
            </Card>
          </ModalBody>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default EditArticle;
