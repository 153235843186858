import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import queryString from 'query-string';
import jwt_decode from 'jwt-decode';
import { post, get } from 'api';

const GoogleVerification = () => {
  const { accessToken, idToken } = queryString.parse(window.location.search);
  const navigate = useNavigate();
  const referralCode = localStorage.getItem('referralCode');
  const preLoginLocation = JSON.parse(
    sessionStorage.getItem('preLoginLocation'),
  );

  useEffect(() => {
    const decodedToken = jwt_decode(idToken);
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('idToken', idToken);
    localStorage.setItem('userId', decodedToken.id);
    localStorage.setItem('userRole', decodedToken.role);
    localStorage.setItem('userData', JSON.stringify(decodedToken));
    localStorage.setItem('currency', '€');
  }, [accessToken, idToken]);

  useEffect(() => {
    if (referralCode) {
      const decodedToken = jwt_decode(idToken);
      const userId = decodedToken.id;
      const updateReferral = async () => {
        await get(`/api/referrals/${referralCode}`)
          .then((res) => res.json())
          .then((data) => {
            const body = {
              referrerId: data.id,
              referredId: userId,
            };
            post(`/api/referrals`, body);
            localStorage.removeItem('referralCode');
          });
      };
      updateReferral();
    }
    if (preLoginLocation) {
      navigate(preLoginLocation);
      sessionStorage.removeItem('preLoginLocation');
    } else {
      setTimeout(() => {
        navigate('/admin/dashboards/backlinks-search', {
          replace: true,
        });
      }, 500);
    }
  }, [idToken, referralCode, navigate, preLoginLocation]);
};

export default GoogleVerification;
