import { useState } from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import { getUserId } from 'api';
import { post } from 'api';

const SubscribePage = () => {
  const [email, setEmail] = useState('');
  const userId = getUserId();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const body = {
      email,
      userId,
    };
    try {
      const response = await post(
        '/api/subscriptions/newsletter-subscribe',
        body,
      );
      const data = await response.json();
      if (response.status === 200) {
        toastSuccess(data.msg);
        setEmail('');
      }
      if (response.status === 400) {
        toastError(data.msg);
        setEmail('');
      }
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <Card extra={'mt-3 w-1/2 h-full bg-white mx-auto'}>
      <div className="container mx-auto flex h-full min-h-[90vh] flex-col px-4 md:flex-row md:justify-between">
        <div className="mx-auto flex flex-col items-center justify-center md:w-2/3">
          <h1 className="text-center text-3xl font-bold">
            Subscribe to our newsletter
          </h1>
          <p className="mt-4 text-center">
            Get the latest news and updates from us.
          </p>

          <form className="mt-8 w-full">
            <input
              type="email"
              className="w-full rounded-md border border-gray-300 px-3 py-2 focus:border-blue-500 focus:outline-none"
              placeholder="Enter your email address"
              autoComplete="email"
              value={email || ''}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
            />
            <button
              type="submit"
              onClick={handleSubmit}
              className="mt-4 w-full rounded-md bg-blue-500 px-3 py-2 text-white"
            >
              Subscribe
            </button>
          </form>
        </div>
      </div>
    </Card>
  );
};

export default SubscribePage;
