import { useNavigate } from 'react-router-dom';

const Footer = () => {
  const navigate = useNavigate();

  return (
    <div className="z-[1.5] mx-auto flex w-full max-w-[90%] flex-col-reverse items-center justify-between px-0 pb-8 pt-8 xl:w-[1170px] xl:max-w-[1170px] xl:flex-row">
      <p className="mt-4 text-center text-sm text-gray-600 md:mt-0 md:text-base">
        ©{1900 + new Date().getYear()} LinkPricer. All Rights Reserved.
      </p>
      <ul className="flex flex-col items-center justify-between md:w-2/3 md:flex-row">
        <li>
          <span
            className="cursor-pointer text-sm text-gray-600 hover:text-gray-600 md:text-base"
            onClick={() => navigate('/subscribe')}
          >
            Subscribe to our newsletter
          </span>
        </li>
        <li>
          <a
            target="blank"
            href="mailto:info@linkpricer.com"
            className="text-sm text-gray-600 hover:text-gray-600 md:text-base"
          >
            Support
          </a>
        </li>
        <li>
          <span
            className="cursor-pointer text-sm text-gray-600 hover:text-gray-600 md:text-base"
            onClick={() => navigate('/privacy-policy')}
          >
            Privacy Policy
          </span>
        </li>
        <li>
          <span
            className="cursor-pointer text-sm text-gray-600 hover:text-gray-600 md:text-base"
            onClick={() => navigate('/terms-of-service')}
          >
            Terms of Service
          </span>
        </li>
      </ul>
    </div>
  );
};

export default Footer;
