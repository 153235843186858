import React, { useMemo, useState, useEffect } from 'react';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import { MdChevronLeft, MdChevronRight } from 'react-icons/md';
import { get, put } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import Switch from 'components/switch';
import InputField from './InputField';

const Users = (props) => {
  const { columnsData } = props;
  const [users, setUsers] = useState([]);
  const [isActivated, setIsActivated] = useState([]);
  const [referralCode, setReferralCode] = useState([]);
  const [referralPercentage, setReferralPercentage] = useState([]);
  const [userCredits, setUserCredits] = useState([]);

  const getUsers = async () => {
    await get('/api/user/all').then((res) =>
      res.json().then((data) => {
        setUsers(data);

        setIsActivated(
          data.map((item) => ({
            id: item.id,
            value: item.isActivated,
          })),
        );

        setReferralCode(
          data.map((item) => ({
            id: item.id,
            value: item.referralCode,
          })),
        );

        setReferralPercentage(
          data.map((item) => ({
            id: item.id,
            value: item.referralPercentage,
          })),
        );

        setUserCredits(
          data.map((item) => ({
            id: item.id,
            value: item.credits,
          })),
        );
      }),
    );
  };

  useEffect(() => {
    getUsers();
  }, []);

  const handleUpdateUser = async (id) => {
    const body = {
      isActivated: isActivated.find((item) => item.id === id)?.value,
      referralCode: referralCode.find((item) => item.id === id)?.value,
      referralPercentage: referralPercentage.find((item) => item.id === id)
        ?.value,
      credits: userCredits.find((item) => item.id === id)?.value,
    };
    await put(`/api/user/update-user/${id}`, body).then((res) => {
      if (res.status === 200) {
        toastSuccess('User updated successfully');
        getUsers();
      } else {
        toastError('Something went wrong');
      }
    });
  };

  const createHandler = (state, setState) => (e, id) => {
    const value =
      e.target.type === 'checkbox' ? e.target.checked : e.target.value;
    if (state.some((item) => item.id === id)) {
      setState((prevState) =>
        prevState.map((item) =>
          item.id === id ? { ...item, value: value } : item,
        ),
      );
    } else {
      setState((prevState) => [...prevState, { id, value }]);
    }
  };

  const handleUserActivation = createHandler(isActivated, setIsActivated);
  const handleRefferalCodeChange = createHandler(referralCode, setReferralCode);
  const handleReferralPercentageChange = createHandler(
    referralPercentage,
    setReferralPercentage,
  );
  const handleCreditsChange = createHandler(userCredits, setUserCredits);

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => users, [users]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-3/4 items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/3"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
        <table
          {...getTableProps()}
          className="w-full table-auto border-collapse whitespace-nowrap"
        >
          <thead>
            {headerGroups.map((headerGroup, index) => (
              <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                {headerGroup.headers.map((column, index) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                    key={index}
                  >
                    <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                      <div className="flex items-center gap-2">
                        {column.render('Header')}
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BsChevronUp className="mr-1 inline h-4 w-4" />
                          ) : (
                            <BsChevronDown className="mr-1 inline h-4 w-4" />
                          )
                        ) : (
                          <IoMdFunnel className="mr-1 inline h-4 w-4" />
                        )}
                      </div>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {page.map((row, index) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  key={index}
                  className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                >
                  {row.cells.map((cell, index) => {
                    let data = '';
                    if (cell.column.Header === 'ID') {
                      data = (
                        <div className="flex items-center gap-2">
                          <div className="text-sm font-bold text-navy-700 dark:text-white">
                            #{cell.value}
                          </div>
                        </div>
                      );
                    } else if (cell.column.Header === 'FIRST NAME') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'LAST NAME') {
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          {cell.value}
                        </div>
                      );
                    } else if (cell.column.Header === 'USER ACTIVATED') {
                      const userId = cell.row.original.id;
                      data = (
                        <div className="text-sm font-bold text-navy-700 dark:text-white">
                          <Switch
                            checked={
                              isActivated.find((item) => item.id === userId)
                                ?.value
                            }
                            onChange={(e) => handleUserActivation(e, userId)}
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'CREDITS') {
                      const userId = cell.row.original.id;
                      data = (
                        <div className="flex items-center gap-2">
                          <InputField
                            extra="w-2/3"
                            id="credits"
                            value={
                              userCredits.find((item) => item.id === userId)
                                ?.value || ''
                            }
                            type="text"
                            placeholder="Credits"
                            name="credits"
                            state="normal"
                            onChange={(e) => handleCreditsChange(e, userId)}
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'REFERRAL CODE') {
                      const userId = cell.row.original.id;
                      data = (
                        <div className="flex items-center gap-2">
                          <InputField
                            extra="w-2/3"
                            id="referralCode"
                            value={
                              referralCode.find((item) => item.id === userId)
                                ?.value || ''
                            }
                            type="text"
                            placeholder="Referral Code"
                            name="referralCode"
                            state="normal"
                            onChange={(e) =>
                              handleRefferalCodeChange(e, userId)
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'REFERRAL BONUS %') {
                      const userId = cell.row.original.id;
                      data = (
                        <div className="flex items-center gap-2">
                          <InputField
                            extra="w-2/3"
                            id="referralPercentage"
                            value={
                              referralPercentage.find(
                                (item) => item.id === userId,
                              )?.value || ''
                            }
                            type="text"
                            placeholder="Referral Bonus %"
                            name="referralPercentage"
                            state="normal"
                            onChange={(e) =>
                              handleReferralPercentageChange(e, userId)
                            }
                          />
                        </div>
                      );
                    } else if (cell.column.Header === 'UPDATE') {
                      const userId = cell.row.original.id;
                      data = (
                        <button
                          onClick={() => handleUpdateUser(userId)}
                          className="linear text-md flex w-1/2 items-center justify-center rounded-full bg-brand-500 p-2 text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200"
                        >
                          Update
                        </button>
                      );
                    }
                    return (
                      <td
                        {...cell.getCellProps()}
                        key={index}
                        className="pb-[16px] pt-[14px] sm:text-[14px]"
                      >
                        {data}
                      </td>
                    );
                  })}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(users.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default Users;
