import React from 'react';
import { Modal, ModalOverlay, ModalContent, ModalBody } from '@chakra-ui/modal';
import { useDisclosure } from '@chakra-ui/hooks';
import Card from 'components/card';
import { MdClose } from 'react-icons/md';
import {
  BacklinkBulkSearchTemplate,
  BacklinkSearchTemplate,
  FiltersTemplate,
} from './templates/InfoModalTemplates';

const InfoModal = ({ isModalOpen, setIsModalOpen, template }) => {
  const { onClose } = useDisclosure();

  console.log('template', template);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    onClose();
  };

  return (
    <Modal
      className="!z-[100] border-none outline-none focus:outline-none"
      isOpen={isModalOpen}
      onClose={onClose}
      disableEnforceFocus
      disableAutoFocus={true}
      autoFocus={false}
    >
      <ModalOverlay className="border-none bg-[#000] !opacity-40 outline-none focus:outline-none" />
      <ModalContent className="top-[2vh] !m-auto max-w-[90%] outline-none md:!max-w-[40%]">
        <ModalBody className="max-h-[90vh] overflow-auto outline-none md:max-h-[96vh]">
          <Card extra={'w-full p-8 rounded-md outline-none focus:outline-none'}>
            <div className="flex justify-end">
              <MdClose className="cursor-pointer" onClick={handleCloseModal} />
            </div>
            {template === 'Backlinks Search' && <BacklinkSearchTemplate />}
            {template === 'filters' && <FiltersTemplate />}
            {template === 'Backlinks Bulk Search' && (
              <BacklinkBulkSearchTemplate />
            )}
          </Card>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default InfoModal;
