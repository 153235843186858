import singleSearchGif from '../../../assets/img/dashboards/singleSearch.gif';
import bulkSearchGif from '../../../assets/img/dashboards/bulkSearch.gif';
import filtersGif from '../../../assets/img/dashboards/filters.gif';

const BacklinkSearchTemplate = () => {
  return (
    <div className="flex flex-col justify-center">
      <p className="mt-2 text-center text-xl font-bold">
        How to Use the Single Domain Search (Backlinks Search)
      </p>
      <p className="text-md mt-4">
        Hey! The Single Domain Search is a quick way to check if a website
        accepts guest posts. Just enter the domain, and you’ll see if they allow
        posts and what their starting price is.
      </p>
      <p className="text-md mt-3 font-bold">Why it’s helpful:</p>
      <p className="text-md mt-1">
        &#x2022; Instantly know if a site allows guest posts.
      </p>
      <p className="text-md">
        &#x2022; See starting prices upfront to plan your outreach better.
      </p>
      <div className="mt-4 flex justify-center">
        <img src={singleSearchGif} alt="Single Search gif" />
      </div>
    </div>
  );
};

const FiltersTemplate = () => {
  return (
    <div className="flex flex-col justify-center">
      <p className="mt-2 text-center text-xl font-bold">
        Understanding "Allowed Niches" (Filters)
      </p>
      <p className="text-md mt-4">
        When using LinkPricer, you’ll notice we have an{' '}
        <strong>"Allowed Niches"</strong>
        section. This is super useful if you're searching for websites that
        accept guest posts in specific niches—especially if you're working in
        more sensitive or grey areas.
      </p>
      <p className="text-md mt-2">
        If you’re looking to see if a site accepts posts in those niches, or you
        need to find pricing for them, just select the niche you're searching
        for. This way, you’ll get results tailored to your specific needs.
      </p>
      <div className="mt-4 flex justify-center">
        <img src={filtersGif} alt="Filters gif" />
      </div>
    </div>
  );
};

const BacklinkBulkSearchTemplate = () => {
  return (
    <div className="flex flex-col justify-center">
      <p className="mt-2 text-center text-xl font-bold">
        How to Use the Backlink Bulk Search
      </p>
      <p className="text-md mt-4">
        Need to check a bunch of websites at once? The Bulk Search feature is
        here to make that easy. Just enter up to 5 domains, and we’ll pull up
        info like guest post availability and pricing for each one—all in one
        go.
      </p>
      <p className="text-md mt-3">
        For example, let’s say you want to search these 5 domains:
      </p>
      <p className="text-md mt-1">&#x2022; nsaidslist.com</p>
      <p className="text-md">&#x2022; dm-gaming.eu</p>
      <p className="text-md">&#x2022; actresstoday.com</p>
      <p className="text-md">&#x2022; zimamagazine.com</p>
      <p className="text-md">&#x2022; scannerbet.com</p>
      <p className="text-md mt-2">
        Pop them into the Bulk Search, and you’ll instantly see which sites
        accept guest posts and what their starting prices are. It's a huge
        time-saver!
      </p>
      <div className="mt-4 flex justify-center">
        <img src={bulkSearchGif} alt="Bulk Search gif" />
      </div>
      <p className="text-md mt-2">
        <strong>Tip:</strong> If you’re looking for guest posts in specific
        niches (especially grey niches), be sure to select the niche you're
        interested in. This way, the results will show whether these sites
        accept posts in your chosen niche.
      </p>
      <div className="mt-4 flex justify-center">
        {/* <img src={defaultGif} alt="Newcomer Popup" /> */}
      </div>
    </div>
  );
};

export { BacklinkSearchTemplate, FiltersTemplate, BacklinkBulkSearchTemplate };
