import TextField from 'components/fields/TextField';
import InputField from 'components/fields/InputField';

const Profile = ({
  featureName,
  setFeatureName,
  featureDescription,
  setFeatureDescription,
}) => {
  return (
    <div className="h-full w-full rounded-[20px] px-3 pt-7 md:px-8">
      {/* Header */}
      <h4 className="pt-[5px] text-xl font-bold text-navy-700 dark:text-white">
        Suggest a Feature
      </h4>
      {/* inputs */}
      <div className="mt-4">
        <InputField
          label="Feature Name"
          placeholder="Enter the name of the feature"
          id="featureName"
          value={featureName || ''}
          onChange={(e) => setFeatureName(e.target.value)}
        />
      </div>
      <div className="mt-2">
        <TextField
          label="Feature Description"
          placeholder="Enter the description of the feature"
          id="textarea"
          cols="30"
          rows="8"
          value={featureDescription || ''}
          onChange={(e) => setFeatureDescription(e.target.value)}
        />
      </div>
    </div>
  );
};

export default Profile;
