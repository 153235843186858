export const referralUrls = {
  'app.linkhouse.co': 'app.linkhouse.co/register/NDA5Z',
  'app.motherlink.io':
    'app.motherlink.io/register/index?ref=YnV0a3VzQGxpbmtwcmljZXIuY29t',
  'newcp.linksmanagement.com':
    'linksmanagement.com/?ref=referral&ref_type=direct&ref_id=7hktzlxltxzbczxt&ref_item=3',
  'cp.adsy.com':
    'ref.adsy.com/?ref=referral&ref_type=direct&ref_id=7hktzlxltxzbczxt&ref_item=3',
  'collaborator.pro': 'collaborator.pro/?ref=9Qxb0G',
  'app.searcheye.io': 'searcheye.io?via=linkpricer',
};
