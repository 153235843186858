export const backlinksHeaders = [
  {
    Header: 'ID',
    accessor: 'id',
  },
  {
    Header: 'DOMAIN NAME',
    accessor: 'domainName',
  },
  {
    Header: 'STATUS',
    accessor: 'inList',
  },
  {
    Header: 'LAST UPDATED',
    accessor: 'updatedAt',
  },
  {
    Header: 'ACTIONS',
    accessor: 'delete',
  },
];
