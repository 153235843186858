import React, { createContext, useCallback, useEffect, useState } from 'react';
import { get, getUserId } from 'api';

export const CreditsContext = createContext({
  currentBalance: 0,
  setCurrentBalance: () => {},
});

export const CreditsProvider = ({ children }) => {
  const userId = getUserId();
  const [currentBalance, setCurrentBalance] = useState(0);

  const fetchCredits = useCallback(
    async (id) => {
      const useUserId = id || userId;
      const response = await get(`/api/credits/${useUserId}`);
      const data = await response.json();
      setCurrentBalance(data[0]?.credits);
    },
    [userId],
  );

  useEffect(() => {
    fetchCredits();
  }, [fetchCredits]);

  return (
    <CreditsContext.Provider
      value={{
        currentBalance,
        setCurrentBalance,
        refreshBalance: fetchCredits,
      }}
    >
      {children}
    </CreditsContext.Provider>
  );
};
