import React, { useMemo, useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { toastSuccess, toastError } from 'utils/toasts';
import Card from 'components/card';
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from 'react-table';
import {
  MdCancel,
  MdCheckCircle,
  MdChevronLeft,
  MdChevronRight,
  MdDeleteForever,
} from 'react-icons/md';
import { remove, get, post, getUserId } from 'api';
import { FiSearch } from 'react-icons/fi';
import { BsChevronDown, BsChevronUp } from 'react-icons/bs';
import { IoMdFunnel } from 'react-icons/io';
import TooltipHorizon from 'components/tooltip';
import DataTable from 'components/loaders/TableSkeleton';
import moment from 'moment';

const WishlistTable = (props) => {
  const { columnsData } = props;
  const navigate = useNavigate();
  const [wishlist, setWishlist] = useState([]);
  const [domainNames, setDomainNames] = useState(null);
  const [dataLoaded, setDataLoaded] = useState(false);
  const [submitting, setSubmitting] = useState(false);
  const userId = getUserId();
  const [userPlan, setUserPlan] = useState({});

  const fetchUserSubscription = async () => {
    const response = await get(`/api/subscriptions/user-plan/${userId}`);
    const data = await response.json();
    setUserPlan(data[0]);
  };

  useEffect(() => {
    fetchUserSubscription();
  }, [userId]);

  useEffect(() => {
    setDataLoaded(true);
  }, [columnsData]);

  const getWishlist = async () => {
    await get(`/api/misc/wishlist/${userId}`).then((res) =>
      res.json().then((data) => {
        setWishlist(data);
      }),
    );
  };

  useEffect(() => {
    getWishlist();
  }, [userId]);

  const handleNewDomainAdd = async () => {
    const isPlanActive = userPlan?.planEndDate >= moment().format('YYYY-MM-DD');
    if (!isPlanActive) {
      toastError(
        'This action requires active subscription plan. Please subscribe to a plan to continue',
        3500,
      );
      return;
    }
    setSubmitting(true);
    if (domainNames === '') {
      toastError('Please enter at least one domain name');
      setSubmitting(false);
    } else {
      const domains = domainNames.split(/[\n,]\s*/);
      const body = {
        userId: userId,
        domainNames: domains,
      };
      try {
        const response = await post('/api/misc/wishlist', body);
        const result = await response.json();
        if (response.status === 200) {
          toastSuccess(result.msg);
          setSubmitting(false);
          setDomainNames(null);
          setTimeout(() => {
            getWishlist();
          }, 1000);
        } else {
          toastError(result.msg);
          setSubmitting(false);
          setDomainNames(null);
          setTimeout(() => {
            getWishlist();
          }, 1000);
        }
        setTimeout(() => {
          getWishlist();
        }, 1000);
      } catch (error) {
        toastError('Something went wrong');
        setSubmitting(false);
        setTimeout(() => {
          getWishlist();
        }, 1000);
      }
      setSubmitting(false);
    }
  };

  const handleRemoveBacklink = async (id) => {
    if (window.confirm('Are you sure you want to delete this domain?')) {
      await remove(`/api/misc/wishlist/${id}`).then((res) => {
        if (res.status === 200) {
          toastSuccess('Successfully removed domain from wishlist');
          getWishlist();
        } else {
          toastError('Something went wrong');
        }
      });
    }
  };

  const columns = useMemo(() => columnsData, [columnsData]);
  const data = useMemo(() => wishlist, [wishlist]);

  const tableInstance = useTable(
    {
      columns,
      data,
      initialState: { pageIndex: 0, pageSize: 15 },
    },
    useGlobalFilter,
    useSortBy,
    usePagination,
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,

    prepareRow,
    nextPage,
    previousPage,

    setGlobalFilter,
    setPageSize,
    state,
    rows,
  } = tableInstance;

  const { pageSize } = state;

  return (
    <Card extra={'w-full sm:overflow-auto p-4'}>
      <div className="mb-4 flex flex-col items-center gap-4 md:flex-row md:items-start">
        <textarea
          className="h-[100px] w-full rounded-[10px] border border-gray-200 bg-white p-4 text-sm text-gray-600 scrollbar-thin scrollbar-thumb-gray-300 md:w-1/3"
          placeholder="Enter or paste domain names here..."
          value={domainNames || ''}
          onChange={(e) => setDomainNames(e.target.value)}
        ></textarea>
        <button
          disabled={submitting}
          onClick={() => handleNewDomainAdd()}
          className="linear flex w-full items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200 md:w-1/6"
        >
          {submitting ? (
            <div className="flex items-center justify-center">
              <div className="mr-2 h-4 w-4 animate-spin rounded-full border-b-2 border-white" />
              <span>Adding..</span>
            </div>
          ) : (
            <span>Add</span>
          )}
        </button>
      </div>

      {/* Search */}
      <div
        onChange={(e) => setGlobalFilter(e.target.value)}
        className="flex w-full items-center rounded-[10px] bg-white shadow-2xl shadow-white dark:!bg-navy-800 dark:shadow-none md:mt-2 md:w-1/3 md:w-3/4"
      >
        <div className="flex h-9 w-full flex-grow items-center rounded-[10px] bg-lightPrimary text-sm text-gray-600 dark:!bg-navy-900">
          <FiSearch className="mx-2 h-4 w-4 !text-gray-700 dark:!text-white" />
          <input
            type="text"
            placeholder="Search...."
            className="block h-full w-full rounded-full bg-lightPrimary text-sm text-navy-700 outline-none dark:!bg-navy-900 dark:text-white"
          />
        </div>
      </div>
      {!dataLoaded ? (
        <DataTable />
      ) : (
        <div className="mt-8 h-full max-w-[100%] overflow-x-auto">
          <table
            {...getTableProps()}
            className="w-full table-auto border-collapse whitespace-nowrap"
          >
            <thead>
              {headerGroups.map((headerGroup, index) => (
                <tr {...headerGroup.getHeaderGroupProps()} key={index}>
                  {headerGroup.headers.map((column, index) => (
                    <th
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                      className="border-b border-gray-200 pb-[10px] pr-16 dark:!border-navy-700"
                      key={index}
                    >
                      <div className="text-start text-xs font-bold tracking-wide text-gray-600 lg:text-xs">
                        <div className="flex items-center gap-2">
                          {column.render('Header')}
                          {column.isSorted ? (
                            column.isSortedDesc ? (
                              <BsChevronUp className="mr-1 inline h-4 w-4" />
                            ) : (
                              <BsChevronDown className="mr-1 inline h-4 w-4" />
                            )
                          ) : (
                            <IoMdFunnel className="mr-1 inline h-4 w-4" />
                          )}
                        </div>
                      </div>
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {page.map((row, index) => {
                prepareRow(row);
                return (
                  <tr
                    {...row.getRowProps()}
                    key={index}
                    className="h-[60px] items-center border-b border-gray-200 dark:!border-white/10"
                  >
                    {row.cells.map((cell, index) => {
                      let data = '';
                      if (cell.column.Header === 'ID') {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className="text-sm font-bold text-navy-700 dark:text-white">
                              #{cell.value}
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === 'DOMAIN NAME') {
                        const domainName = cell.row.original.domainName;
                        const inList = cell.row.original.inList;
                        data = inList ? (
                          <TooltipHorizon
                            key={index}
                            extra="max-h-[200px] overflow-y-auto"
                            trigger={
                              <p
                                className="cursor-pointer text-sm font-bold text-navy-700 hover:underline"
                                onClick={() =>
                                  navigate(
                                    `/admin/dashboards/backlinks-search`,
                                    {
                                      state: { domainName: domainName },
                                    },
                                  )
                                }
                              >
                                {domainName && domainName.slice(0, 18)}
                                {domainName && domainName.length > 18 && (
                                  <span>...</span>
                                )}
                              </p>
                            }
                            content={
                              <p className="text-sm font-bold text-navy-700">
                                View full domain details
                              </p>
                            }
                            placement="top"
                          />
                        ) : (
                          <p className="text-sm font-bold text-navy-700">
                            {domainName && domainName.slice(0, 18)}
                            {domainName && domainName.length > 18 && (
                              <span>...</span>
                            )}
                          </p>
                        );
                      } else if (cell.column.Header === 'STATUS') {
                        const status = cell.value;
                        const statusText =
                          status === 1 ? 'Available' : 'Not Available Yet';
                        data = (
                          <div className="flex items-center gap-2">
                            <div className={`rounded-full text-xl`}>
                              {status === 1 ? (
                                <MdCheckCircle className="text-green-500" />
                              ) : status === 0 ? (
                                <MdCancel className="text-red-500" />
                              ) : (
                                ''
                              )}
                            </div>
                            <p className="text-sm font-bold text-navy-700 dark:text-white">
                              {statusText}
                            </p>
                          </div>
                        );
                      } else if (cell.column.Header === 'LAST UPDATED') {
                        data = (
                          <div className="flex items-center gap-2">
                            <div className="text-sm font-bold text-navy-700 dark:text-white">
                              {moment(cell.value).format('YYYY-MM-DD HH:mm')}
                            </div>
                          </div>
                        );
                      } else if (cell.column.Header === 'ACTIONS') {
                        const itemId = cell.row.original.id;
                        const domainName = cell.row.original.domainName;
                        data = (
                          <div className="flex items-center gap-2">
                            {/* <EditBacklinkModal
                              itemId={itemId}
                              domainName={domainName}
                              getWishlist={getWishlist}
                            /> */}
                            <button
                              onClick={() => handleRemoveBacklink(itemId)}
                              className="text-2xl font-bold text-navy-700 hover:text-red-500"
                            >
                              <MdDeleteForever />
                            </button>
                          </div>
                        );
                      }
                      return (
                        <td
                          {...cell.getCellProps()}
                          key={index}
                          className="pb-[16px] pt-[14px] sm:text-[14px]"
                        >
                          {data}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {/* pagination */}
      <div className="mt-10 flex h-[80px] w-full items-center justify-between md:px-6">
        {/* left side */}
        <div className="flex items-center gap-2">
          <select
            value={pageSize}
            onChange={(e) => setPageSize(Number(e.target.value))}
            className="h-10 w-[70px] rounded-xl border border-gray-200 px-2 text-sm  text-gray-600 dark:!border-white/10 dark:!bg-navy-800"
            name=""
            id=""
          >
            <option value="5">5</option>
            <option value="10">10</option>
            <option value="15">15</option>
            <option value="20">20</option>
            <option value="50">50</option>
          </select>
          <p className="text-sm text-gray-700">
            Showing {page.length} of {rows.length}
          </p>
        </div>
        {/* right side */}
        <div className="flex items-center gap-2">
          <button
            onClick={() => previousPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronLeft />
          </button>

          <p className="text-sm text-gray-700">
            {state.pageIndex + 1} of {Math.ceil(wishlist.length / pageSize)}
          </p>

          <button
            onClick={() => nextPage()}
            className={`linear flex items-center justify-center rounded-full bg-brand-500 p-2 text-lg text-white transition duration-200 hover:bg-brand-600 active:bg-brand-700 dark:bg-brand-400 dark:text-white dark:hover:bg-brand-300 dark:active:bg-brand-200`}
          >
            <MdChevronRight />
          </button>
        </div>
      </div>
    </Card>
  );
};

export default WishlistTable;
